import React, {useState, useEffect} from 'react'
import { Row, Col } from 'react-bootstrap'
import Meta from '../components/Meta'
import PDF from '../components/assets/LeConcierge-Application.pdf'

const MembershipScreen = ({ match }) => {

  const [posts, setPosts] = useState(null);

    useEffect(() => {
      async function loadPosts() {
        const response = await fetch('https://leconcierge.dreamhosters.com/wp-json/wp/v2/pages/99');
        if(!response.ok) {
          // oups! something went wrong
          return;
        }
        
        const posts = await response.json();
        // console.log("Posts:", posts)
        setPosts(posts);
        // console.log(posts)
      }
    
      loadPosts();
   }, [])

   if (posts === null) return null

  return (
    <>
      <Meta />
     
      <h1>JOIN US</h1>
      
        <>
          <Row>
            <Col sm={12} md={12} lg={12} xl={12}>
              <h2>{posts.acf.intro_header}</h2>
              <p>
                {posts.acf.intro_body}
              </p>
            </Col>
          </Row>
          
          <hr/>
          {/* <Row>
            <Col sm={12} md={12} lg={6} xl={6}>
              <h2><div dangerouslySetInnerHTML={{__html: posts.acf.section_one_header}} /></h2>
              <p><div dangerouslySetInnerHTML={{__html: posts.acf.section_one_text_area}} /></p>
              <a href="/register" className="btn btn-primary mb-50">REGISTER</a>
            </Col>
            <Col sm={12} md={12} lg={6} xl={6}>
              <img src={posts.acf.section_one_image} alt="Video" className='video'/>
            </Col>
          </Row> */}
          <Row>
            <Col sm={12} md={12} lg={6} xl={6}>
              <h2><div dangerouslySetInnerHTML={{__html: posts.acf.section_two_header}} /></h2>
              <p><div dangerouslySetInnerHTML={{__html: posts.acf.section_two_text_area}} /></p>
              <a href={PDF} target="_blank" rel="noopener noreferrer" className="btn btn-primary mb-50">APPLICATION</a>
            </Col>
            <Col sm={12} md={12} lg={6} xl={6}>
              <img src={posts.acf.section_two_image} alt="Video" className='video'/>
            </Col>
          </Row>
          
        </>
    </>
  )
}

export default MembershipScreen
