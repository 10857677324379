import axios from 'axios'
import {
    EXCHANGE_RATE_UPDATE_REQUEST,
    EXCHANGE_RATE_UPDATE_SUCCESS,
    EXCHANGE_RATE_UPDATE_FAIL,
} from '../constants/settingsConstants'
import { logout } from './userActions'

export const updateExchangeRate = (exchangeRate) => async (dispatch, getState) => {
    try {
        dispatch({
            type: EXCHANGE_RATE_UPDATE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.put(`/api/settings/exchangerate`, { exchangeRate }, config)

        dispatch({
            type: EXCHANGE_RATE_UPDATE_SUCCESS,
            payload: data,
        })
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        if (message === 'Not authorized, token failed') {
            dispatch(logout())
        }
        dispatch({
            type: EXCHANGE_RATE_UPDATE_FAIL,
            payload: message,
        })
    }
}

export const getSettings = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: EXCHANGE_RATE_UPDATE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.get(`/api/settings`, config)

        dispatch({
            type: EXCHANGE_RATE_UPDATE_SUCCESS,
            payload: data,
        })
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        if (message === 'Not authorized, token failed') {
            dispatch(logout())
        }
        dispatch({
            type: EXCHANGE_RATE_UPDATE_FAIL,
            payload: message,
        })
    }
}