import React, { useEffect } from 'react'
import { Table, Button, Row, Col } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
// import { getUserDetails } from '../actions/userActions'
import { listMyBookings } from '../actions/bookingActions'
// import { USER_UPDATE_PROFILE_RESET } from '../constants/userConstants'

const UserBookingsScreen = ({ location, history }) => {
  // const [name, setName] = useState('')
  // const [email, setEmail] = useState('')
  // const [password, setPassword] = useState('')
  // const [confirmPassword, setConfirmPassword] = useState('')
  // const [message, setMessage] = useState(null)

  const dispatch = useDispatch()

  const userDetails = useSelector((state) => state.userDetails)
  const { user } = userDetails

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile)
  const { success } = userUpdateProfile

  const bookingListMy = useSelector((state) => state.bookingListMy)
  const { loading: loadingBookings, error: errorBookings, bookings } = bookingListMy

  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    // } else if (!userInfo.isVerified) {
    //   history.push('/verify-pending')
    } else {
      if (userInfo) {
        // dispatch(getUserDetails('profile'))
        dispatch(listMyBookings())
      } 
    }
  }, [dispatch, history, userInfo, user, success])

  return (
    <Row>
      <Col md={12}>
        <h2>My Bookings</h2>
        {loadingBookings ? (
          <Loader />
        ) : errorBookings ? (
          <Message variant='danger'>{errorBookings}</Message>
        ) : (
          <Table striped bordered hover responsive className='table-sm table-white'>
            <thead>
              <tr>
                <th>BOOKING ID</th>
                <th>DATE</th>
                <th>TOTAL</th>
                {/* <th>PAID</th> */}
                <th>CONFIRMED</th>
                <th>DETAILS</th>
              </tr>
            </thead>
            <tbody>
              {bookings.map((booking, i) => (
                // console.log('booking', booking),
                <tr key={booking._id}>
                  <td>{booking._id}</td>
                  <td>{booking.createdAt.substring(0, 10)}</td>
                  <td>${booking.totalPrice}</td>
                  {/* <td>
                    {booking.isPaid ? (
                      booking.paidAt.substring(0, 10)
                    ) : (
                      <i className='fas fa-times' style={{ color: 'red' }}></i>
                    )}
                  </td> */}
                  <td>
                    {booking.isDelivered ? (
                      <i className='fas fa-check' style={{ color: 'green' }}></i>
                    ) : (
                      <i className='fas fa-times' style={{ color: 'red' }}></i>
                    )}
                  </td>
                  <td>
                    <LinkContainer to={`/booking/${booking._id}`}>
                      <Button className='btn-sm' variant='light'>
                        VIEW
                      </Button>
                    </LinkContainer>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Col>
    </Row>
  )
}

export default UserBookingsScreen
