import React from 'react'
import ReactGA from 'react-ga4';
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import Header from './components/Header'
import Footer from './components/Footer'
import HomeScreen from './screens/HomeScreen'
import AboutScreen from './screens/AboutScreen'
import ContactScreen from './screens/ContactScreen'
import ProductScreen from './screens/ProductScreen'
import PackagesScreen from './screens/PackagesScreen'
import ServicesScreen from './screens/ServicesScreen'
import MembershipScreen from './screens/MembershipScreen'
import RatesScreen from './screens/RatesScreen'
import TermsScreen from './screens/TermsScreen'
import RefundScreen from './screens/RefundScreen'
import FAQScreen from './screens/FAQScreen'
import PrivacyPolicyScreen from './screens/PrivacyPolicyScreen'
import CartScreen from './screens/CartScreen'
import LogoutScreen from './screens/LogoutScreen'
import LoginScreen from './screens/LoginScreen'
import SignUpScreen from './screens/SignUpScreen'
import VerifyScreen from './screens/VerifyScreen'
import VerifyPendingScreen from './screens/VerifyPendingScreen'
import WelcomeScreen from './screens/WelcomeScreen'
import PasswordResetScreen from './screens/PasswordResetScreen'
import ResetPasswordScreen from './screens/ResetPasswordScreen'
import RegisterScreen from './screens/RegisterScreen'
import ProfileScreen from './screens/ProfileScreen'
import AdminSettingsScreen from './screens/AdminSettingsScreen'
import SettingsScreen from './screens/SettingsScreen'
import FlightScreen from './screens/FlightScreen'
import PassengerScreen from './screens/PassengerScreen'
// import PaymentScreen from './screens/PaymentScreen'
import PlaceBookingScreen from './screens/PlaceBookingScreen'
import BookingScreen from './screens/BookingScreen'
import UserListScreen from './screens/UserListScreen'
import UserEditScreen from './screens/UserEditScreen'
import MessageUserScreen from './screens/MessageUserScreen'
import ProductListScreen from './screens/ProductListScreen'
import ProductEditScreen from './screens/ProductEditScreen'
import BookingListScreen from './screens/BookingListScreen'
import BookingListArrivalsScreen from './screens/BookingListArrivalsScreen'
import BookingListDeparturesScreen from './screens/BookingListDeparturesScreen'
import BookingListTransitScreen from './screens/BookingListTransitScreen'
import BookingListBothScreen from './screens/BookingListBothScreen'
import UserBookingsScreen from './screens/UserBookingsScreen'


ReactGA.initialize("G-3H1GTRQ4QG");

const App = () => {
  return (
    <Router>
      <Header />
      <main className='py-3'>
        <Container>
          <Route path='/booking/:id' component={BookingScreen} />
          <Route path='/flight-details' component={FlightScreen} />
          <Route path='/add-passengers' component={PassengerScreen} />
          {/* <Route path='/payment' component={PaymentScreen} /> */}
          <Route path='/place-booking' component={PlaceBookingScreen} />
          <Route path='/logout' component={LogoutScreen} />
          <Route path='/login' component={LoginScreen} />
          <Route path='/sign-up' component={SignUpScreen} />
          <Route path='/register' component={RegisterScreen} />
          <Route path='/reset/:email/:passwordToken' component={ResetPasswordScreen} exact />
          <Route path='/verify/:id/:confirmationCode' component={VerifyScreen} exact />
          <Route path='/verify-pending' component={VerifyPendingScreen} />
          <Route path='/welcome' component={WelcomeScreen} />
          <Route path='/forgotpassword' component={PasswordResetScreen} />
          <Route path='/admin/settings' component={AdminSettingsScreen} />
          <Route path='/settings' component={SettingsScreen} />
          <Route path='/profile' component={ProfileScreen} />
          <Route path='/bookings' component={UserBookingsScreen} />
          <Route path='/product/:id' component={ProductScreen} />
          <Route path='/cart/:id?' component={CartScreen} />
          <Route path='/admin/userlist' component={UserListScreen} />
          <Route path='/admin/user/:id/edit' component={UserEditScreen} />
          <Route path='/admin/user/:id/message' component={MessageUserScreen} />
          <Route
            path='/admin/productlist'
            component={ProductListScreen}
            exact
          />
          <Route
            path='/admin/productlist/:pageNumber'
            component={ProductListScreen}
            exact
          />
          <Route path='/admin/product/:id/edit' component={ProductEditScreen} />
          <Route path='/admin/bookinglist' component={BookingListScreen} exact/>
          <Route path='/admin/bookinglist/arrivals' component={BookingListArrivalsScreen} exact/>
          <Route path='/admin/bookinglist/departures' component={BookingListDeparturesScreen} exact/>
          <Route path='/admin/bookinglist/arrivals-and-departures' component={BookingListBothScreen} exact/>
          <Route path='/admin/bookinglist/transit' component={BookingListTransitScreen} exact/>
          <Route path='/search/:keyword' component={HomeScreen} exact />
          <Route path='/page/:pageNumber' component={HomeScreen} exact />
          <Route
            path='/search/:keyword/page/:pageNumber'
            component={HomeScreen}
            exact
          />

          <Route path='/privacy-policy' component={PrivacyPolicyScreen} exact />
          <Route path='/terms-and-conditions' component={TermsScreen} exact />
          <Route path='/refund-policy' component={RefundScreen} exact />
          <Route path='/faqs' component={FAQScreen} exact />
          <Route path='/contact-us' component={ContactScreen} exact />
          <Route path='/rates' component={RatesScreen} exact />
          <Route path='/membership' component={MembershipScreen} exact />
          <Route path='/services' component={ServicesScreen} exact />
          <Route path='/reservations' component={PackagesScreen} exact />
          <Route path='/about' component={AboutScreen} exact />
          <Route path='/' component={HomeScreen} exact />
        </Container>
      </main>
      <Footer />
    </Router>
  )
}

export default App
