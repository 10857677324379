import React, { useState, useEffect } from 'react'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { ListGroup } from 'react-bootstrap'

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { Uploader } from "uploader";
import { UploadButton } from "react-uploader";

import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { getUserDetails, updateUserProfile } from '../actions/userActions'
import { listMyBookings } from '../actions/bookingActions'
import { USER_UPDATE_PROFILE_RESET } from '../constants/userConstants'

const SettingsScreen = ({ location, history }) => {
  const [firstName, setFirstName] = useState('')
  const [middleName, setMiddleName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [userPhoto, setUserPhoto] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [country, setCountry] = useState('')
  const [passportNum, setPassportNum] = useState('')
  const [message, setMessage] = useState(null)

  const [documents, setDocuments] = useState([]);
  const [fileUrls, setFileUrls] = useState([]);

  // Initialize once (at the start of your app).
  const uploader = Uploader({ 
    apiKey: "public_kW15b8YFLzsKVB9JvpRG5GS6vEkE", // Your real API key.
    onSuccess: (file) => {
      setUserPhoto(file.url);
    },
  }); 

  const healthUploader = Uploader({
    apiKey: "public_kW15b8YFLzsKVB9JvpRG5GS6vEkE", // Your real API key.
    // onSuccess map through the files and set the state
    onSuccess: (files) => {
      setDocuments(files);
    },
  });

  const dispatch = useDispatch()

  const userDetails = useSelector((state) => state.userDetails)
  const { loading, error, user } = userDetails

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile)
  const { success } = userUpdateProfile

  // const bookingListMy = useSelector((state) => state.bookingListMy)
  // const { loading: loadingBookings, error: errorBookings, bookings } = bookingListMy

  useEffect(() => {
    async function listFolder(params) {  
      const baseUrl  = "https://api.upload.io";
      const path     = `/v2/accounts/${params.accountId}/folders/list`;
      const entries  = obj => Object.entries(obj).filter(([,val]) => (val ?? null) !== null);
      const query    = entries(params.querystring ?? {})
                        .flatMap(([k,v]) => Array.isArray(v) ? v.map(v2 => [k,v2]) : [[k,v]])
                        .map(kv => kv.join("=")).join("&");
      const response = await fetch(`${baseUrl}${path}${query.length > 0 ? "?" : ""}${query}`, {
        method: "GET",
        headers: Object.fromEntries(entries({
          "Authorization": `Bearer ${params.apiKey}`,
        }))
      });
      const result = await response.json();
      if (Math.floor(response.status / 100) !== 2)
        throw new Error(`Upload API Error: ${JSON.stringify(result)}`);
      return result;
    }
  
    listFolder({
      accountId: "kW15b8Y",
      apiKey: "secret_kW15b8Y9DuYK7BHz3h3cmYDjR3Bx",
      querystring: {
        folderPath: `/uploads/LCC/Users/${user._id}/Documents`,
    //  - Optional -
    //  cursor: "aGVsbG8=",
    //  dryRun: true,
    //  includeFiles: true,
    //  includeOverriddenStorage: true,
    //  includePhysicalFolders: true,
    //  includeVirtualFolders: true,
    //  limit: 50,
    //  recursive: true
      }
    }).then(
      response => {
        const fileUrls = response.items.map(item => item.fileUrl);
        setFileUrls(fileUrls);
      },
      error => {
        console.error(error);
      }
    );
  }, [user._id]);

  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    // } else if (!userInfo.isVerified) {
    //   history.push('/verify-pending')
    } else {
      if (!user || !user.firstName || success) {
        dispatch({ type: USER_UPDATE_PROFILE_RESET })
        dispatch(getUserDetails('profile'))
        dispatch(listMyBookings())
      } else {
        setFirstName(user.firstName)
        setEmail(user.email)
        setUserPhoto(user.userPhoto)
      }
    }
  }, [dispatch, history, userInfo, userPhoto, user, success])

  const submitHandler = (e) => {
    e.preventDefault()
    if (password !== confirmPassword) {
      setMessage('Passwords do not match')
    } else {
      dispatch(updateUserProfile({ 
        id: user._id, 
        userPhoto,
        firstName,
        middleName,
        lastName,
        phone,
        email, 
        password 
      }))
    }
  }

  return (
    <>

      <Tabs
        defaultActiveKey="profile"
        id="justify-tab-example"
        className="mb-3"
        justify
      >
        <Tab eventKey="profile" title="Profile">
        <h2>Your Profile</h2>
          <Row>

            <Col md={6}>
              <h2>Upload Photo</h2>
              <p>Upload a photo of yourself</p>

              {/* Check if userPhoto exists */}
              {userPhoto ? (
                <img src={userPhoto} alt="userPhotoa" width="200" height="200" />
              ) : (
                <img src={user.userPhoto} alt="userPhotos" width="200" height="200" />
              )}

                    <br/>
                    <br/>
                      
                      <UploadButton uploader={uploader}
                        options={{ 
                          multi: false,
                          mimeTypes: ["image/jpeg", "image/png"],
                          maxFileSizeBytes: 10000000, // 10MB
                          tags: ["userPhoto", "profilePhoto"],
                          path: {
                            folderPathVariablesEnabled: true,
                            folderPath: `/uploads/LCC/Users/${user._id}/ProfilePhoto`,
                          }
                        }}
                        // onComplete={files => alert(files.map(x => x.fileUrl).join("\n"))}>
                        // onComplete set the file to the userPhoto state and then call dispatch to update the user profile
                        onComplete={files => {
                          // Set the userPhoto to the file URL.
                          setUserPhoto(files.map(x => x.fileUrl).join("\n"));
                          // Update the user profile.
                          dispatch(updateUserProfile({ 
                            id: user._id, 
                            userPhoto: files.map(x => x.fileUrl).join("\n"),
                          }))
                        }
                        }>
                          
                        
                        {({onClick}) =>
                          <button onClick={onClick} className="mb-50">
                            Select Photo
                          </button>
                        }
                      </UploadButton>
                      
                      <br/>
                      {/* <p>Photo URL: {userPhoto}</p> */}
                    {/* Check if photo exists before displaying it */}
                    
                      
            </Col>

            <Col md={6}>
            <h2>User Profile</h2>
            {message && <Message variant='danger'>{message}</Message>}
            {}
            {success && <Message variant='success'>Profile Updated</Message>}
            {loading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>{error}</Message>
            ) : (
              <>
              <ListGroup variant='flush'>
                <ListGroup.Item>
                  Name: {user.firstName + ' ' + user.middleName + ' ' + user.lastName}
                </ListGroup.Item>
                <ListGroup.Item>
                  Email: {user.email}
                </ListGroup.Item>
                <ListGroup.Item>
                  Date of Birth: {user.birthday}
                </ListGroup.Item>
                <ListGroup.Item>
                  Phone: {user.phone}
                </ListGroup.Item>
              </ListGroup>
              <br/>
              <ListGroup variant='flush'>
                <ListGroup.Item>
                  Address:<br/> 
                  {user.address},<br/>
                  {user.city}, {user.state} {user.postalCode},<br/>
                  {user.country}
                </ListGroup.Item>
              </ListGroup>
              </>
            )}
            {/* <h2 className='mt-50'>update information</h2> */}
            </Col>


          </Row>
        </Tab>
        <Tab eventKey="Documents" title="Documents">
          <h2>Your Documents</h2>
          <p>Kindly upload your introductory letter here. This is a mandatory requirement for all first time users and your booking can’t be successful without it.
Here’s a sample you can use as a guide:</p>
          <h5 style={{fontWeight: 'bold'}}>Examples of Introductory Letters</h5>
          <ul>
            <li><a href="/letter/leconciergeclub_letter_guidelines.pdf" target="_blank" rel="noopener noreferrer">Introductory Letter Guidelines</a></li>
            <li><a href="/letter/leconciergeclub_letter.docx" target="_blank" rel="noopener noreferrer">Introductory Letter Word Document</a></li>
          </ul>
          <p>
          You may also upload proof of covid vaccination, visa on arrival documents and any other documents you wish to submit
          </p>
          <UploadButton uploader={healthUploader}
            options={{ 
                      multi: true,
                      mimeTypes: ["image/jpeg", "image/png", "application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"],
                      maxFileSizeBytes: 20000000, // 20MB
                      tags: ["documents", "health", "covid"],
                      path: {
                        folderPathVariablesEnabled: true,
                        folderPath: `/uploads/LCC/Users/${user._id}/Documents`,
                      }
                    }}
                    // onComplete={files => alert(files.map(x => x.fileUrl).join("\n"))}>
                    // onComplete set the file to the userPhoto state and then call dispatch to update the user profile
                    onComplete={files => {
                      // Set the userPhoto to the file URL.
                      setDocuments(files.map(x => x.fileUrl).join("\n"));
                      // Update the user documents.
                      dispatch(updateUserProfile({
                        id: user._id,
                        documents: documents
                      }))
                    }
                    }>
                    
                    {({onClick}) =>
                      <button onClick={onClick} className="mb-50">
                        Select Files
                      </button>
                    }
          </UploadButton>
          <hr/>
          <ListGroup>
            {fileUrls.map((url, index) => (
              <ListGroup.Item key={index}>
                <a href={url} target="_blank" rel="noopener noreferrer">
                  File {index + 1}
                </a>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Tab>
        <Tab eventKey="settings" title="Settings">
        <h2>Your Information</h2>
          <Row>
        
        <Col md={6}>
          {message && <Message variant='danger'>{message}</Message>}
          {}
          {success && <Message variant='success'>Profile Updated</Message>}
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            <Form onSubmit={submitHandler}>
              <Form.Group controlId='firstName'>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type='name'
                  placeholder='Enter name'
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                ></Form.Control>
              </Form.Group>
              
              <Form.Group controlId='middleName'>
                <Form.Label>Middle Name</Form.Label>
                <Form.Control
                  type='name'
                  placeholder='Enter name'
                  value={middleName}
                  onChange={(e) => setMiddleName(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='lastName'>
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type='name'
                  placeholder='Enter last name'
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId='phone'>
                <Form.Label>Phone #</Form.Label>
                <Form.Control
                  type='phone'
                  placeholder='Enter phone #'
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId='email'>
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type='email'
                  placeholder='Enter email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId='password'>
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type='password'
                  placeholder='Enter password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId='confirmPassword'>
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type='password'
                  placeholder='Confirm password'
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Button type='submit' variant='primary'>
                Update
              </Button>
            </Form>
          )}
        </Col>
        <Col md={6}>
          {/* <h2>TRAVEL INFORMATION</h2> */}
          <Form onSubmit={submitHandler}>
              <Form.Group controlId='phone'>
                <Form.Label>Phone #</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter phone #'
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='passportNum'>
                <Form.Label>Passport #</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Passport #'
                  value={passportNum}
                  onChange={(e) => setPassportNum(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId='address'>
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter address'
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId='city'>
                <Form.Label>City</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter your town or city'
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId='postalCode'>
                <Form.Label>Postal Code</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Postal Code'
                  value={postalCode}
                  onChange={(e) => setPostalCode(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='country'>
                <Form.Label>Country</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Confirm country'
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                ></Form.Control>
              </Form.Group>

      
            </Form>
        </Col>
      </Row>
        </Tab>
      </Tabs>
    </>
  )
}

export default SettingsScreen
