import {
    EXCHANGE_RATE_UPDATE_REQUEST,
    EXCHANGE_RATE_UPDATE_SUCCESS,
    EXCHANGE_RATE_UPDATE_FAIL,
    WELCOME_MESSAGE_UPDATE_REQUEST,
    WELCOME_MESSAGE_UPDATE_SUCCESS,
    WELCOME_MESSAGE_UPDATE_FAIL,
    CONFIRMATION_EMAIL_UPDATE_REQUEST,
    CONFIRMATION_EMAIL_UPDATE_SUCCESS,
    CONFIRMATION_EMAIL_UPDATE_FAIL,
    CANCELLATION_EMAIL_UPDATE_REQUEST,
    CANCELLATION_EMAIL_UPDATE_SUCCESS,
    CANCELLATION_EMAIL_UPDATE_FAIL
} from '../constants/settingsConstants'

export const exchangeRateReducer = (state = {}, action) => {
    switch (action.type) {
        case EXCHANGE_RATE_UPDATE_REQUEST:
            return { loading: true }
        case EXCHANGE_RATE_UPDATE_SUCCESS:
            return { loading: false, success: true, settings: action.payload }
        case EXCHANGE_RATE_UPDATE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const welcomeMessageReducer = (state = {}, action) => {
    switch (action.type) {
        case WELCOME_MESSAGE_UPDATE_REQUEST:
            return { loading: true }
        case WELCOME_MESSAGE_UPDATE_SUCCESS:
            return { loading: false, success: true }
        case WELCOME_MESSAGE_UPDATE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const confirmationEmailReducer = (state = {}, action) => {
    switch (action.type) {
        case CONFIRMATION_EMAIL_UPDATE_REQUEST:
            return { loading: true }
        case CONFIRMATION_EMAIL_UPDATE_SUCCESS:
            return { loading: false, success: true }
        case CONFIRMATION_EMAIL_UPDATE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const cancellationEmailReducer = (state = {}, action) => {
    switch (action.type) {
        case CANCELLATION_EMAIL_UPDATE_REQUEST:
            return { loading: true }
        case CANCELLATION_EMAIL_UPDATE_SUCCESS:
            return { loading: false, success: true }
        case CANCELLATION_EMAIL_UPDATE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}
