import React, { useState } from 'react'
import { Form, Container, Button, Row, Col } from 'react-bootstrap'
import Message from '../components/Message'
import { useDispatch } from 'react-redux'
// import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { resetPasswordLink } from '../actions/userActions'

const PasswordResetScreen = ({ location, history }) => {
  const [email, setEmail] = useState('')
  const [success, setSuccess] = useState(false)

  const dispatch = useDispatch()

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(resetPasswordLink({email}))

    setSuccess(true)
  }



  return (
    <>
      <Container>
        <Row className='justify-content-md-center'>
          <Col xs={12} sm={12} md={8} lg={8}> 
          <FormContainer>
            <h1>Reset your password</h1>
            {success && <Message variant='success'>Success, the link is on the way!</Message>}
            <p>Enter your email address below and we'll send you a link to reset your password.
                If nothing happens, please check your spam folder.</p>
            <br/>
            <Form onSubmit={submitHandler}>
              <Form.Group controlId='email'>
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type='email'
                  placeholder='Enter email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <br/>
              <Button type='submit' variant='primary'>
                SUBMIT
              </Button>
            </Form>
          </FormContainer>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default PasswordResetScreen
