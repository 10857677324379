import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { logout } from '../actions/userActions'

const LogoutScreen = () => {

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin


  useEffect(() => {
    if (userInfo) {
      dispatch(logout())
    }

  }, [userInfo, dispatch])

  return (
    <>
      
    </>
  )
}

export default LogoutScreen
