import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Container, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { login } from '../actions/userActions'

const LoginScreen = ({ location, history }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [posts, setPosts] = useState(null);

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  const redirect = location.search ? location.search.split('=')[1] : '/'

  useEffect(() => {
    if (userInfo) {
      history.push(redirect)
    }
    async function loadPosts() {
      const response = await fetch('https://leconcierge.dreamhosters.com/wp-json/wp/v2/pages/169');
      if(!response.ok) {
        // oups! something went wrong
        return;
      }
      
      const posts = await response.json();
      // console.log("Rates:", posts)
      setPosts(posts);
      // console.log(posts)
    }
  
    loadPosts();

  }, [history, userInfo, redirect])

  if (posts === null) return null

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(login(email, password))
  }

  return (
    <>
      <Container>
        <Row className='justify-content-md-center'>
          <Col xs={12} sm={12} md={6} lg={6} className="mt-50">
            <div dangerouslySetInnerHTML={{__html: posts.content.rendered}} />
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}> 
          <FormContainer>
            <h1>Sign In</h1>
            {error && <Message variant='danger'>{error}</Message>}
            {loading && <Loader />}
            <Form onSubmit={submitHandler}>
              <Form.Group controlId='email'>
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type='email'
                  placeholder='Enter email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId='password'>
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type='password'
                  placeholder='Enter password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Button type='submit' variant='primary'>
                Sign In
              </Button>
            </Form>
            <Row className='py-3'>
              <Col>
                New member?{' '}
                <Link to={redirect ? `/register?redirect=${redirect}` : '/register'}>
                  Click here to create an account
                </Link>
                <br/>
                <Link to='/forgotpassword'>Forgot Password?</Link>
              </Col>
            </Row>
            
        
      </FormContainer>
          </Col>
        </Row>
      </Container>
      
    </>
  )
}

export default LoginScreen
