import React from 'react'
import { Button } from 'react-bootstrap'

const Product = ({ product }) => {

  // const addToCartHandler = () => {
  //   // history.push(`/cart/${product._id}`)
  //   console.log('Add to cart button clicked: /cart/', product._id)
  // }

  return (

    // Div flex container with image on left and text on right
    // <div className='flex flex-column-md'>
      <div className='product flex flex-column-md'>
        <div className='product-image'>
          <img src={product.image} alt={product.name} />
        </div>
        <div className='product-details'>
          <div className='product-title'>{product.name}</div>
          {/* <div className='product-price'>Adult: ${product.priceAdult} &bull; Child: ${product.priceChild} &bull; Infant: ${product.priceInfant} </div> */}
          <div className='product-description'><p dangerouslySetInnerHTML={{__html: product.description}} /></div>
          <div className='product-reserve'>
            <Button
              type='button'
              className='btn-block mt-20'
              // onClick={addToCartHandler}
              href={`/cart/${product._id}`}
            >
              Reserve the service
            </Button>
          </div>
        </div>
      </div>
    // </div>
    // <Card className='my-3 rounded'>
    //   <Link to={`/product/${product._id}`}>
    //     <Card.Img src={product.image} variant='top' />
    //   </Link>

    //   <Card.Body>
    //     <Link to={`/product/${product._id}`}>
    //       <Card.Title as='div'>
    //         <strong>{product.name}</strong>
    //       </Card.Title>

    //     {/* <Card.Text as='div'>
    //       {product.description}
    //     </Card.Text> */}

    //     {/* <Card.Text as='h3'>${product.price}</Card.Text> */}
    //     <Button
    //               type='button'
    //               className='btn-block mt-20'
    //             >
    //               Reserve
    //             </Button>
    //     </Link>

    //   </Card.Body>
    // </Card>
  )
}

export default Product
