import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import { Row, Col, Modal, Button } from 'react-bootstrap'
import { useLocation, useHistory } from 'react-router-dom';
import Meta from '../components/Meta'

// Videos
import topVideo from '../images/video/LCC_Trailer.mp4'

const AboutScreen = ({ match }) => {

  const [posts, setPosts] = useState(null);

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const location = useLocation()
  const history = useHistory()

  const handleShow = (e) => {
    e.preventDefault();
    // Check if user is logged in, if so redirect to reservation page
    if (userInfo) {
      setShow(false);
      history.push('/reservations')
    } else {
      setShow(true);
      // Don't redirect to reservations page
    }

    // Check the url to see if the user is on the profile page
    // If so, add the active class to the profile link
    if (location.pathname === '/profile') {
      document.querySelector('.dropdown-toggle').classList.add('active')
    }
  }

  const handleMember = () => {
    setShow(false);
    window.location.href = '/login'
  }

  const handleNonMember = () => {
    setShow(false);
    history.push('/register')
  }

    useEffect(() => {
      async function loadPosts() {
        const response = await fetch('https://leconcierge.dreamhosters.com/wp-json/wp/v2/pages/92');
        if(!response.ok) {
          // oups! something went wrong
          return;
        }
        
        const posts = await response.json();
        // console.log("Home:", posts)
        setPosts(posts);
        // console.log(posts)
      }
    
      loadPosts();
   }, [])

   if (posts === null) return null


  return (
    <>
      <Meta />
        <>
          <Row className='no-mt'>
            <h1>ABOUT US</h1>
            <Col sm={12} md={12} lg={12} xl={12} className="justify-content-center text-center" style={{padding: 0}}>
              {/* <div className='video mt-50' dangerouslySetInnerHTML={{__html: posts.acf.main_video}} /> */}
              {/* <div className='video mt-50'>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/fIp9tgYkM5s?controls=0?&modestbranding=1&showinfo=0&fs=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowfullscreen></iframe>
              </div> */}
              <div className='video'>
                <video width="100%" height="auto" controls>
                  <source src={topVideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </Col>
          </Row>
          <Row  className="justify-content-center">
            <Col sm={12} md={12} lg={10} xl={10} className="text-center mt-20 mb-50">
              <h2 className='large-h2'><div dangerouslySetInnerHTML={{__html: posts.acf.intro_header}} /></h2>
              
                <div dangerouslySetInnerHTML={{__html: posts.acf.intro_body_text}} />
              
              <a href="/reservations" onClick={handleShow}>
                <button className='mt-50'>Reserve</button>
              </a>
            </Col>
            
          </Row>

          <Row>
          <Col sm={12} md={12} lg={6} xl={6} className="text-center-mobile">
              <h2 dangerouslySetInnerHTML={{__html: posts.acf.second_header}} />
              <p>
                <div dangerouslySetInnerHTML={{__html: posts.acf.second_text_area}} />
              </p>
            </Col>
          </Row>

          <Modal show={show} onHide={handleClose} centered>
            <Modal.Body className='dark center text-center'>
              You must have an account to reserve a service. 
            </Modal.Body>
            <Modal.Footer className='center'>
              <Button variant="primary" onClick={handleNonMember}>
                CREATE AN ACCOUNT
              </Button>
              <Button variant="primary" onClick={handleMember}>
                EXISTING ACCOUNT
              </Button>
            </Modal.Footer>
          </Modal>

        </>
    </>
  )
}

export default AboutScreen
