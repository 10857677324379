import React, { useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'
// import { verifyUser } from '../actions/userActions'

const WelcomeScreen = ({ match, history }) => {

  // const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin


  useEffect(() => {
    if (!userInfo.isVerified) {
      // console.log("User isn't logged in or isVerified: WelcomeScreen");
      history.push('/login')
    } 
  }, [history, userInfo])

  // if (userInfo && userInfo.isVerified === true) {
    
    return (
      <>
        <Container>
          <Row className='justify-content-md-center'>
            <Col xs={12} sm={12} md={12} lg={12} className="mt-50">
              <h2>Welcome {userInfo.firstName}! Your account has been confirmed</h2>
              <p>Email: {userInfo.email}</p>

              <p>Continue to your bookings by selecting the option from the menu. 
                From there you can complete your reservations and have full access to the site.
                </p>
            </Col>
          
          </Row>
        </Container>
      </>
    )

  // }
}

export default WelcomeScreen;
