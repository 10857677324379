import axios from 'axios'
import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_SAVE_FLIGHT_DETAILS,
  CART_SAVE_PASSENGERS,
  CART_SAVE_PAYMENT_METHOD,
} from '../constants/cartConstants'

// Add item/update items in cart
export const addToCart = (id, qtyAdult=0, qtyYoungAdult=0, qtyChild=0, qtyInfant=0) => async (dispatch, getState) => {
  const { data } = await axios.get(`/api/products/${id}`)

  dispatch({
    type: CART_ADD_ITEM,
    payload: {
      product: data._id,
      name: data.name,
      image: data.image,
      priceAdult: data.priceAdult,
      priceYoungAdult: data.priceYoungAdult,
      priceChild: data.priceChild,
      priceInfant: data.priceInfant,
      countInStock: data.countInStock,
      qtyAdult,
      qtyYoungAdult,
      qtyChild,
      qtyInfant,
      qty: qtyAdult + qtyYoungAdult + qtyChild + qtyInfant,
      totalPrice: (data.priceAdult * qtyAdult) + (data.priceYoungAdult * qtyYoungAdult) + (data.priceChild * qtyChild) + (data.priceInfant * qtyInfant),
    },
  })

  // console.log('cartItems: ', getState().cart.cartItems)
  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

// Remove item from cart
export const removeFromCart = (id) => (dispatch, getState) => {
  dispatch({
    type: CART_REMOVE_ITEM,
    payload: id,
  })

  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

// Save Passenger Details
export const savePassengerDetails = (data) => async (dispatch) => {

  // const { data } = await axios.get(`/api/bookings/`)

  dispatch({
    type: CART_SAVE_PASSENGERS,
    payload: data,
  })

  localStorage.setItem('passengerDetails', JSON.stringify(data))
  // console.log('passengerDetails: ', localStorage.getItem('passengerDetails'))
}

// Save Flight Details
export const saveflightDetails = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_FLIGHT_DETAILS,
    payload: data,
  })

  localStorage.setItem('flightDetails', JSON.stringify(data))
}

// Save Payment Method
export const savePaymentMethod = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_PAYMENT_METHOD,
    payload: data,
  })

  localStorage.setItem('paymentMethod', JSON.stringify(data))
}
