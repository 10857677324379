import React, { useState } from 'react'
import { Row, Col, Form, Button, Card, ListGroup } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../components/FormContainer'
import CheckoutSteps from '../components/CheckoutSteps'
import Message from '../components/Message'
import { saveflightDetails } from '../actions/cartActions'

const FlightScreen = ({ history }) => {
  const cart = useSelector((state) => state.cart)
  const { flightDetails, cartItems } = cart

  // console.log("flightdetails:",flightDetails)
  // console.log("cartItems:",cartItems)

  const [airline, setAirline] = useState(flightDetails.airline)
  const [flightNo, setFlightNo] = useState(flightDetails.flightNo)
  const [arrivalDate, setArrivalDate] = useState(flightDetails.arrivalDate)
  const [arrivalTime, setArrivalTime] = useState(flightDetails.arrivalTime)
  const [departureDate, setDepartureDate] = useState(flightDetails.departureDate)
  const [departureTime, setDepartureTime] = useState(flightDetails.departureTime)

  const [message, setMessage] = useState('')
  const [noErrors, setNoErrors] = useState(false)

  const dispatch = useDispatch()

  // const submitHandler = (e) => {
  //   e.preventDefault()
  //   dispatch(saveflightDetails({ airline, flightNo, arrivalDate, arrivalTime, departureDate, departureTime }))
  //   history.push('/payment')
  // }

  const nextPageHandler = (e) => {
    e.preventDefault()
    // Perform validation
    if (airline === '') {
      setMessage('Please enter the airline')
      setNoErrors(false)
      return
    } else if (flightNo === '') {
      setMessage('Please enter the flight number')
      setNoErrors(false)
      return
    } else {
      setNoErrors(true)
    }

    {cartItems.map((item) => {
      // Loop through cart items, if a product contains "Arrival" check that fields are not empty
      if (item.name.includes("Arrival")) {
        if (arrivalDate === '') {
          setMessage('Please enter the arrival date')
          setNoErrors(false)
          return false
        } else if (arrivalTime === '') {
          setMessage('Please enter the arrival time')
          setNoErrors(false)
          return false
        } else {
          setNoErrors(true)
          return true
        }
      } else if 
      // Loop through cart items, if a product contains "Departure" check that fields are not empty
      (item.name.includes("Departure")) {
        if (departureDate === '') {
          setMessage('Please enter the departure date')
          setNoErrors(false)
          return false
        } else if (departureTime === '') {
          setMessage('Please enter the departure time')
          setNoErrors(false)
          return false
        } else {
          setNoErrors(true)
          return true
        }
      } else if
      // Loop through cart items, if a product contains "Round Trip" or "Pick Up" check that fields are not empty
      (item.name.includes("Round Trip")) {
        if (arrivalDate === '') {
          setMessage('Please enter the arrival date')
          setNoErrors(false)
          return false
        } else if (arrivalTime === '') {
          setMessage('Please enter the arrival time')
          setNoErrors(false)
          return false
        } else if (departureDate === '') {
          setMessage('Please enter the departure date')
          setNoErrors(false)
          return false
        } else if (departureTime === '') {
          setMessage('Please enter the departure time')
          setNoErrors(false)
          return false
        } else {
          setNoErrors(true)
          return true
        }
      }
    })}

    // If no errors, save flight details and go to next page
    if (noErrors) {
      dispatch(saveflightDetails({ airline, flightNo, arrivalDate, arrivalTime, departureDate, departureTime }))
      history.push('/place-booking')
    } else {
      // If errors, do not save flight details and do not go to next page
      setMessage('Something went wrong. Please try again.')
      return
    }
  }

  return (
    <FormContainer>
      <CheckoutSteps step1 step2 />
      <Row>
        <Col xl={8} lg={8} md={8} sm={12}>
        <h1>Flight Details</h1>
      <p>
        Please enter the flight details for your booking. We need this information
        to ensure that your booking is valid and keep track of your flight status.
      </p>
      <hr />
      {message && <Message variant='danger'>{message}</Message>}
      <Form>
        <Form.Group controlId='airline'>
          <Form.Label>Airline</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter airline'
            value={airline}
            required
            onChange={(e) => setAirline(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='flightNo'>
          <Form.Label>Flight Number</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter flight number'
            value={flightNo}
            required
            onChange={(e) => setFlightNo(e.target.value)}
          ></Form.Control>
        </Form.Group>

        {/* Loop through cart items, if a product contains "Arrival" show arrival form */}
        {cartItems.map((item) => {
          if (item.name.includes("Arrival")) {
            return (
              <>
                <Form.Group controlId='arrivalDate'>
                  <Form.Label>Arrival Date</Form.Label>
                  <Form.Control
                    type='date'
                    placeholder='Enter arrival date'
                    value={arrivalDate}
                    required
                    onChange={(e) => setArrivalDate(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId='arrivalTime'>
                  <Form.Label>Arrival Time</Form.Label>
                  <Form.Control
                    type='time'
                    placeholder='Enter arrival time'
                    value={arrivalTime}
                    required
                    onChange={(e) => setArrivalTime(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </>
            )
          }
          return null
        })}

        {/* Loop through cart items, if a product contains "Departure" show arrival form */}
        {cartItems.map((item) => {
          if (item.name.includes("Departure")) {
            return (
              <>
                <Form.Group controlId='departureTime'>
                  <Form.Label>Departure Date</Form.Label>
                  <Form.Control
                    type='date'
                    placeholder='Enter departure date'
                    value={departureDate}
                    required
                    onChange={(e) => setDepartureDate(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId='departureTime'>
                  <Form.Label>Departure Time</Form.Label>
                  <Form.Control
                    type='time'
                    placeholder='Enter departure time'
                    value={departureTime}
                    required
                    onChange={(e) => setDepartureTime(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </>
            )
          }
          return null
        })}

        {/* Loop through cart items, if a product contains "Round Trip" or "Pick Up" show both arrival and departure form */}
        {cartItems.map((item) => {
          if (item.name.includes("Round Trip")) {
            return (
              <>
                <Form.Group controlId='arrivalDate'>
                  <Form.Label>Arrival Date</Form.Label>
                  <Form.Control
                    type='date'
                    placeholder='Enter arrival date'
                    value={arrivalDate}
                    required
                    onChange={(e) => setArrivalDate(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId='arrivalTime'>
                  <Form.Label>Arrival Time</Form.Label>
                  <Form.Control
                    type='time'
                    placeholder='Enter arrival time'
                    value={arrivalTime}
                    required
                    onChange={(e) => setArrivalTime(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId='departureTime'>
                  <Form.Label>Departure Date</Form.Label>
                  <Form.Control
                    type='date'
                    placeholder='Enter departure date'
                    value={departureDate}
                    required
                    onChange={(e) => setDepartureDate(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId='departureTime'>
                  <Form.Label>Departure Time</Form.Label>
                  <Form.Control
                    type='time'
                    placeholder='Enter departure time'
                    value={departureTime}
                    required
                    onChange={(e) => setDepartureTime(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </>
            )
          }
          return null
        })}

        {/* <Button type='submit' variant='primary'>
          Continue
        </Button> */}
      </Form>
        </Col>
        <Col xl={4} lg={4} md={4} sm={12}>
          <h1>Order Summary</h1>
          <Card>
            <ListGroup variant='flush'>
              <ListGroup.Item>
                <h2>{cartItems[0].name}</h2>
                <h2>Passengers: ({cartItems.reduce((acc, item) => acc + item.qty, 0)})</h2>
                <h5>Adults: {cartItems[0].qtyAdult}</h5>
                <h5>Children: {cartItems[0].qtyChild}</h5>
                <h5>Infants: {cartItems[0].qtyInfant}</h5><br/>
                <h5>VAT(19.25%):  ${ (19.25 / 100) * cartItems[0].totalPrice}</h5>
                <h5>Total: ${cartItems[0].totalPrice + (19.25 / 100) * cartItems[0].totalPrice.toFixed(2)}</h5>


              </ListGroup.Item>
              <ListGroup.Item>
                <Button
                  type='button'
                  className='btn-block'
                  disabled={cartItems.length === 0}
                  onClick={nextPageHandler}
                >
                  Proceed To Confirmation
                </Button>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
      </Row>
      
    </FormContainer>
  )
}

export default FlightScreen
