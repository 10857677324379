import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainerFull from '../components/FormContainerFull'
import { login } from '../actions/userActions'

const SignUpScreen = ({ location, history }) => {
  const [firstName, setFirstName] = useState('')
  const [middleName, setMiddleName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [country, setCountry] = useState('')
  const [passport, setPassport] = useState('')
  

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  const redirect = location.search ? location.search.split('=')[1] : '/'

  useEffect(() => {
    if (userInfo) {
      history.push(redirect)
    }
  }, [history, userInfo, redirect])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(login(email, password))
  }

  return (
    <Row>
      <Col md={8}>
        <FormContainerFull>
          <h1>CREATE YOUR ACCOUNT</h1>
          {error && <Message variant='danger'>{error}</Message>}
          {loading && <Loader />}

          <Form onSubmit={submitHandler}>
          <Form.Group controlId='firstName'>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter your first name'
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='middleName'>
              <Form.Label>Middle Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter your middle name'
                value={middleName}
                onChange={(e) => setMiddleName(e.target.value)}
                required
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='lastName'>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter your last name'
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='email'>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type='email'
                placeholder='Enter email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='password'>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type='password'
                placeholder='Enter password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='address'>
              <Form.Label>Address</Form.Label>
              <Form.Control
                type='address'
                placeholder='Enter your address'
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='city'>
              <Form.Label>City</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter your city or town'
                value={city}
                onChange={(e) => setCity(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='postalCode'>
              <Form.Label>Postal Code</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter your postal code'
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='country'>
              <Form.Label>Country</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter your country'
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='passport'>
              <Form.Label>Passport Number</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter your passport number'
                value={passport}
                onChange={(e) => setPassport(e.target.value)}
              ></Form.Control>
            </Form.Group>

            
            <Button type='submit' variant='primary'>
              REGISTER
            </Button>
          </Form>

          <Row className='py-3'>
            <Col>
              New Customer?{' '}
              <Link to={redirect ? `/register?redirect=${redirect}` : '/register'}>
                Register
              </Link>
            </Col>
          </Row>
        </FormContainerFull>
      </Col>
      <Col md={4}>
        <h1>WHY DO I NEED AN ACCOUNT?</h1>
        <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer venenatis vel magna in vestibulum. 
          Integer pellentesque sem massa, et auctor mauris malesuada mattis. Sed quis est a enim consequat 
          laoreet eu vel augue. Proin placerat scelerisque lobortis. Aenean ac blandit enim. Integer rutrum 
          neque ut pulvinar eleifend. Nulla facilisi. Vestibulum vitae fringilla magna. Praesent justo lectus, 
          tempor in interdum at, blandit non sem. Suspendisse quis sem vel est malesuada ultrices. Fusce eu 
          euismod mi. Quisque ligula mauris, euismod at cursus in, porta vel leo. Nulla ac eros augue. Aenean 
          sodales augue quis arcu gravida, ut congue massa lacinia.
        </p>
       </Col>
    </Row>
    
  )
}

export default SignUpScreen
