import React, {useState, useEffect} from 'react'
import { Row, Col, Table } from 'react-bootstrap'
import Meta from '../components/Meta'

const RatesScreen = ({ match }) => {

  const [posts, setPosts] = useState(null);

    useEffect(() => {
      async function loadPosts() {
        const response = await fetch('https://leconcierge.dreamhosters.com/wp-json/wp/v2/pages/61');
        if(!response.ok) {
          // oups! something went wrong
          return;
        }
        
        const posts = await response.json();
        console.log("Rates:", posts)
        setPosts(posts);
        // console.log(posts)
      }
    
      loadPosts();
   }, [])

   if (posts === null) return null

  return (
    <>
      <Meta />
     
      <h1>Rates</h1>
      
        <>
          {/* <Row>
            <Col sm={12} md={12} lg={12} xl={12}>
              <div dangerouslySetInnerHTML={{__html: posts.content.rendered}} />
              
            </Col>
          </Row> */}

          <Row>
            <Col sm={12} md={12} lg={12} xl={12} className="mt-20">
              <h2>Daily Rates</h2>
              <p>These rates are only for the departure and arrival services, flights are not included.</p>
              <div className='flex  flex-column-md' style={{overflow: 'auto'}}>
                
                <Table striped bordered hover variant="dark">
                  <thead>
                    <tr>
                      <th>AGE GROUP</th>
                      <th>One Way</th>
                      {/* <th>ARRIVAL & DEPARTURE</th> */}
                      <th>Transit</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Adult (18+ years)</td>
                      <td>$310</td>
                      {/* <td>$600</td> */}
                      <td>$410</td>
                    </tr>
                    <tr>
                      <td>Young Adult (12 - 17 years)</td>
                      <td>$205</td>
                      {/* <td>$400</td> */}
                      <td>$305</td>
                    </tr>
                    <tr>
                      <td>Children (5 - 11 years)</td>
                      <td>$100</td>
                      {/* <td>$200</td> */}
                      <td>$200</td>
                    </tr>
                    <tr>
                      <td>Under 5s</td>
                      <td>Complimentary</td>
                      {/* <td>Complimentary</td> */}
                      <td>Complimentary</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              
            </Col>
          </Row>
          
          
        </>
    </>
  )
}

export default RatesScreen
