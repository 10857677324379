import React, { useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { verifyUser } from '../actions/userActions'

const VerifyScreen = ({ history }) => {

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  // console.log("VerifyScreen userInfo: ", userInfo);

  // const [isVerified, setVerified] = useState(null)

  useEffect(() => { 
    const parts = window.location.pathname.split('/');
    const id = parts[2]; 
    const confirmToken = parts[3]; 

    if (!userInfo || !userInfo.isVerified) {
      // console.log("User isn't logged in or verified");
      dispatch(verifyUser(id, confirmToken))
    } else if (userInfo.isVerified) {
      // console.log("User is logged in and isVerified");
      history.push('/welcome')
    }
  }, [dispatch, userInfo, history])

    return (
      <>
        <Container>
          <Row className='justify-content-md-center'>
            <Col xs={12} sm={12} md={12} lg={12} className="mt-50">
              <h2>Welcome!</h2>
              <p>Your account has been verified! Feel free to browser our services and begin booking for your trip.</p>
              <p>
                <a href="/reservations">
                  <button className='mt-50'>Reservations</button>
                </a>
              </p>
              <p>
                <a href="/services">
                  <button className='mt-50'>Services</button>
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </>
    )

}

export default VerifyScreen
