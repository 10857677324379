import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Row, Col, Form, Button } from 'react-bootstrap'
import Message from '../components/Message'
import { contactForm } from '../actions/userActions'
import Meta from '../components/Meta'

// Images
import firstPicture from "../images/hp_2.jpg"
import secondPicture from "../images/contact_2.jpg"
// import videoThumb from "../images/video/videoThumb.png"

const ContactScreen = ({ match }) => {

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [messageSuccess, setMessageSuccess] = useState(false)
  const [messageError, setMessageError] = useState(false)

  const [posts, setPosts] = useState(null);

  useEffect(() => {
    async function loadPosts() {
      const response = await fetch('https://leconcierge.dreamhosters.com/wp-json/wp/v2/pages/101');
      if(!response.ok) {
        // oups! something went wrong
        return;
      }
        
      const posts = await response.json();
      // console.log("Contact:", posts)
      setPosts(posts);
      // console.log(posts)
    }
    
    loadPosts();
  }, [])

  const dispatch = useDispatch()

  const submitHandler = (e) => {
    e.preventDefault()

    if (!name || !email || !subject || !message) {
      setMessageError(true)
      return
    }

    dispatch(contactForm({ name, email, subject, message}))
    setMessageSuccess(true)
    // Clear form
    setName('')
    setEmail('')
    setSubject('')
    setMessage('')
  }

  if (posts === null) return null

  return (
    <>
      <Meta />
        <>
          <Row className='mt-50'>
          <Col sm={12} md={12} lg={6} xl={6}>
      <h1>CONTACT US</h1>

              <h2 className='mb-0'>Phone</h2>
              <p dangerouslySetInnerHTML={{__html: '+233552115555<br/>+' + posts.acf.phone_number}} />
              <h2 className='mb-0'>Email</h2>
              <p dangerouslySetInnerHTML={{__html: posts.acf.email}} />
              <h2 className='mb-0'>Location</h2>
              <p dangerouslySetInnerHTML={{__html: posts.acf.location}} />
            </Col>
            <Col sm={12} md={12} lg={6} xl={6}>
              <iframe style={{filter: `invert(90%)`, border: 0, width: '100%'}} title='LCC Location' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3970.735034265499!2d-0.17031218467913833!3d5.606100834701011!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdf9b1fff72b87d%3A0xff2fe8ba5dfa0561!2sKotoka%20International%20Airport!5e0!3m2!1sen!2sus!4v1668845106577!5m2!1sen!2sus" width="600" height="450" allowFullScreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </Col>
          </Row>


          <Row>
            <Col className="text-center mt-50">
            {messageSuccess && <Message variant='success'>Message sent successfully!</Message>}
            {messageError && <Message variant='danger'>Please fill out the form correctly</Message>}
              <h2>Send A Message</h2>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={12} lg={8} xl={8} className='center'>
              <Form onSubmit={submitHandler}>
                <Form.Group controlId='name'>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter your name'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId='email'>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter your email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId='subject'>
                  <Form.Label>Subject</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter subject'
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId='message'>
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    type='text'
                    as='textarea'
                    rows={10}
                    placeholder='Enter your message'
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Button type='submit' variant='primary'>
                  Send
                </Button>
              </Form>
            </Col>
          </Row>

          <Row>
            <Col className="text-center mt-50">
              <h2 className='mt-50'>You will know you are here when you see</h2>
            </Col>
          </Row>
          <Row>
            
            <Col sm={12} md={12} lg={6} xl={6}>
              <img src={firstPicture} alt="Video" className='video'/>
            </Col>
            <Col sm={12} md={12} lg={6} xl={6}>
              <img src={secondPicture} alt="Video" className='video'/>
            </Col>
            
          </Row>
        </>
    </>
  )
}

export default ContactScreen
