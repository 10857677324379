import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Navbar, Nav, Container, NavDropdown, Modal, Button } from 'react-bootstrap'
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import { logout } from '../actions/userActions'

import Logo from "../images/art/LCCLogo.svg"
import LogoMobile from "../images/art/LogoMobile.svg"
import backgroundVideo from "../images/video/videobg.mp4"

const Header = () => {

  const location = useLocation()
  const history = useHistory()
  const isAdminPage = location.pathname.startsWith('/admin');

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const logoutHandler = () => {
    dispatch(logout())
  }

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = (e) => {
    e.preventDefault();
    // Check if user is logged in, if so redirect to reservation page
    if (userInfo) {
      setShow(false);
      history.push('/reservations')
    } else {
      setShow(true);
      // Don't redirect to reservations page
    }

    // Check the url to see if the user is on the profile page
    // If so, add the active class to the profile link
    if (location.pathname === '/profile') {
      document.querySelector('.dropdown-toggle').classList.add('active')
    }
  }

  const handleMember = () => {
    setShow(false);
    window.location.href = '/login'
  }

  const handleNonMember = () => {
    setShow(false);
    history.push('/register')
  }

  // Function to remove "show" class from navbar-collapse when a link is clicked
  const removeShow = () => {
    document.querySelector('.navbar-collapse').classList.remove('show')
  }

  return (
    <>
      <header className='menu-shadow'>
        
        <Container style={{display: 'flex'}}>
          <div className="logo">
            <a href='/'>
              <img src={Logo} alt="Desktop Logo" width="300" className='desktop'/>
              <img src={LogoMobile} alt="Mobile Logo" width="60" className='mobile'/>
            </a>
          </div>
          <Navbar expand="lg" variant='dark'>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id='basic-navbar-nav'>
                <Nav>
                  <NavLink to='/about' activeClassName="active" onClick={() => removeShow()}>About</NavLink>
                  <NavLink to='/services' activeClassName="active" onClick={() => removeShow()}>Services</NavLink>
                  <NavLink to='/rates' activeClassName="active" onClick={() => removeShow()}>Rates</NavLink>
                  <NavLink to='/contact-us' activeClassName="active" onClick={() => removeShow()}>Contact</NavLink>
                  {(userInfo && userInfo.isAdmin) && (
                    <NavDropdown title="Admin" id="basic-nav-dropdown" className={isAdminPage ? 'active' : ''}>
                      {userInfo.isAdmin && (
                        <>
                          <NavDropdown.Item href="/admin/userlist">LCC Clients</NavDropdown.Item>
                          <NavDropdown.Item href="/admin/productlist">LCC Services</NavDropdown.Item>
                          <NavDropdown.Item href="/admin/bookinglist">Reservations</NavDropdown.Item>
                          <NavDropdown.Item href="/admin/settings">Settings</NavDropdown.Item>
                        </>
                      )}
                    </NavDropdown>
                  )}
                  <NavLink to='/reservations' activeClassName="active" onClick={handleShow}>
                    Reserve
                  </NavLink>
                  {(userInfo && !userInfo.isAdmin) && (
                    <NavDropdown title="Account" id="basic-nav-dropdown" >
                      <NavDropdown.Item href="/profile">Profile</NavDropdown.Item>
                      <NavDropdown.Item href="/bookings">Bookings</NavDropdown.Item>
                    </NavDropdown>
                  )}
                  {userInfo && ( 
                    <NavLink to={'/logout'} exact onClick={logoutHandler}>Logout</NavLink> 
                    )} 
                  {!userInfo && ( 
                    <NavLink to='/login' activeClassName="active"  onClick={() => removeShow()}>Login</NavLink>
                  )}
                </Nav>
              </Navbar.Collapse>
          
          </Navbar>
        </Container>
      </header>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body className='dark center text-center'>
          You must have an account to reserve a service. 
        </Modal.Body>
        <Modal.Footer className='center'>
          <Button variant="primary" onClick={handleNonMember}>
            CREATE AN ACCOUNT
          </Button>
          <Button variant="primary" onClick={handleMember}>
            EXISTING ACCOUNT
          </Button>
        </Modal.Footer>
      </Modal>

      <video autoPlay loop muted id="videobg">
        <source src={backgroundVideo} type='video/mp4' />
      </video>
    </>
  )
}

export default Header
