import React, { useState, useEffect } from 'react'
import { Form, Container, Button, Row, Col } from 'react-bootstrap'
import Message from '../components/Message'
import { useDispatch } from 'react-redux'
// import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { forgotPassword} from '../actions/userActions'

const ResetPasswordScreen = ({ location, history }) => {
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const [passwordToken, setPasswordToken] = useState('')
  const [success, setSuccess] = useState(false)


  const dispatch = useDispatch()

  useEffect(() => {
    const parts = window.location.pathname.split('/');
    const email = parts[2];
    const passwordToken = parts[3];
    setEmail(email)
    setPasswordToken(passwordToken)
  }, [dispatch, history])


  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(forgotPassword(email, passwordToken, password))

    setSuccess(true)
  }



  return (
    <>
      <Container>
        <Row className='justify-content-md-center'>
          <Col xs={12} sm={12} md={8} lg={8}> 
          <FormContainer>
            <h1>Enter your new password</h1>
            {success && <Message variant='success'>Success! <p>You can try logging in with your new password now.</p>
            <p><a href="/login" style={{color: '#000'}}>Click here to try again</a>.</p></Message>}
            
            <br/>
            <Form onSubmit={submitHandler}>
              <Form.Group controlId='password'>
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  type='password'
                  placeholder='Enter password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <br/>
              <Button type='submit' variant='primary'>
                SUBMIT
              </Button>
            </Form>
          </FormContainer>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default ResetPasswordScreen
