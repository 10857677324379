export const EXCHANGE_RATE_UPDATE_REQUEST = 'EXCHANGE_RATE_UPDATE_REQUEST'
export const EXCHANGE_RATE_UPDATE_SUCCESS = 'EXCHANGE_RATE_UPDATE_SUCCESS'
export const EXCHANGE_RATE_UPDATE_FAIL = 'EXCHANGE_RATE_UPDATE_FAIL'

export const WELCOME_MESSAGE_UPDATE_REQUEST = 'WELCOME_MESSAGE_UPDATE_REQUEST'
export const WELCOME_MESSAGE_UPDATE_SUCCESS = 'WELCOME_MESSAGE_UPDATE_SUCCESS'
export const WELCOME_MESSAGE_UPDATE_FAIL = 'WELCOME_MESSAGE_UPDATE_FAIL'

export const CONFIRMATION_EMAIL_UPDATE_REQUEST = 'CONFIRMATION_EMAIL_UPDATE_REQUEST'
export const CONFIRMATION_EMAIL_UPDATE_SUCCESS = 'CONFIRMATION_EMAIL_UPDATE_SUCCESS'
export const CONFIRMATION_EMAIL_UPDATE_FAIL = 'CONFIRMATION_EMAIL_UPDATE_FAIL'

export const CANCELLATION_EMAIL_UPDATE_REQUEST = 'CANCELLATION_EMAIL_UPDATE_REQUEST'
export const CANCELLATION_EMAIL_UPDATE_SUCCESS = 'CANCELLATION_EMAIL_UPDATE_SUCCESS'
export const CANCELLATION_EMAIL_UPDATE_FAIL = 'CANCELLATION_EMAIL_UPDATE_FAIL'