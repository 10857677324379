export const BOOKING_CREATE_REQUEST = 'BOOKING_CREATE_REQUEST'
export const BOOKING_CREATE_SUCCESS = 'BOOKING_CREATE_SUCCESS'
export const BOOKING_CREATE_FAIL = 'BOOKING_CREATE_FAIL'
export const BOOKING_CREATE_RESET = 'BOOKING_CREATE_RESET'

export const BOOKING_DETAILS_REQUEST = 'BOOKING_DETAILS_REQUEST'
export const BOOKING_DETAILS_SUCCESS = 'BOOKING_DETAILS_SUCCESS'
export const BOOKING_DETAILS_FAIL = 'BOOKING_DETAILS_FAIL'

export const BOOKING_PAY_REQUEST = 'BOOKING_PAY_REQUEST'
export const BOOKING_PAY_SUCCESS = 'BOOKING_PAY_SUCCESS'
export const BOOKING_PAY_FAIL = 'BOOKING_PAY_FAIL'
export const BOOKING_PAY_RESET = 'BOOKING_PAY_RESET'

export const BOOKING_LIST_MY_REQUEST = 'BOOKING_LIST_MY_REQUEST'
export const BOOKING_LIST_MY_SUCCESS = 'BOOKING_LIST_MY_SUCCESS'
export const BOOKING_LIST_MY_FAIL = 'BOOKING_LIST_MY_FAIL'
export const BOOKING_LIST_MY_RESET = 'BOOKING_LIST_MY_RESET'

export const BOOKING_LIST_REQUEST = 'BOOKING_LIST_REQUEST'
export const BOOKING_LIST_SUCCESS = 'BOOKING_LIST_SUCCESS'
export const BOOKING_LIST_FAIL = 'BOOKING_LIST_FAIL'

export const BOOKING_DELIVER_REQUEST = 'BOOKING_DELIVER_REQUEST'
export const BOOKING_DELIVER_SUCCESS = 'BOOKING_DELIVER_SUCCESS'
export const BOOKING_DELIVER_FAIL = 'BOOKING_DELIVER_FAIL'
export const BOOKING_DELIVER_RESET = 'BOOKING_DELIVER_RESET'

export const BOOKING_CANCEL_REQUEST = 'BOOKING_CANCEL_REQUEST'
export const BOOKING_CANCEL_SUCCESS = 'BOOKING_CANCEL_SUCCESS'
export const BOOKING_CANCEL_FAIL = 'BOOKING_CANCEL_FAIL'
export const BOOKING_CANCEL_RESET = 'BOOKING_CANCEL_RESET'

export const BOOKING_ADD_PASSENGER_PHOTO_REQUEST = 'BOOKING_ADD_PASSENGER_PHOTO_REQUEST'
export const BOOKING_ADD_PASSENGER_PHOTO_SUCCESS = 'BOOKING_ADD_PASSENGER_PHOTO_SUCCESS'
export const BOOKING_ADD_PASSENGER_PHOTO_FAIL = 'BOOKING_ADD_PASSENGER_PHOTO_FAIL'
export const BOOKING_ADD_PASSENGER_PHOTO_RESET = 'BOOKING_ADD_PASSENGER_PHOTO_RESET'

export const BOOKING_LIST_BY_USER_REQUEST = 'BOOKING_LIST_BY_USER_REQUEST'
export const BOOKING_LIST_BY_USER_SUCCESS = 'BOOKING_LIST_BY_USER_SUCCESS'
export const BOOKING_LIST_BY_USER_FAIL = 'BOOKING_LIST_BY_USER_FAIL'