import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { getUserDetails, messageUser } from '../actions/userActions'
import { USER_UPDATE_RESET } from '../constants/userConstants'

const MessageUserScreen = ({ match, history }) => {
  const userId = match.params.id

  // const [email, setEmail] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [messageSuccess, setMessageSuccess] = useState(false)

  const dispatch = useDispatch()

  const userDetails = useSelector((state) => state.userDetails)
  const { loading, error, user } = userDetails

  const userUpdate = useSelector((state) => state.userUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = userUpdate

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: USER_UPDATE_RESET })
      // history.push('/admin/userlist')
    } else {
      if (!user.firstName || user._id !== userId) {
        dispatch(getUserDetails(userId))
        // setEmail(user.email)
      }
    }
  }, [dispatch, history, userId, user, successUpdate])

  const submitHandler = (e) => {
    e.preventDefault()
    // Set email to user.email
    const email = user.email
    dispatch(messageUser({ email, subject, message}))
    setMessageSuccess(true)
    // Clear form
    setSubject('')
    setMessage('')
  }

  return (
    <>
      <Link to='/admin/userlist' className='btn btn-light my-3'>
        Go Back
      </Link>
      <FormContainer>
        <h1>Message User</h1>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
        {messageSuccess && <Message variant='success'>Message sent successfully!</Message>}
        <hr/>
        <p>{user.firstName} {user.lastName}<br/>{user.email}</p>
        <hr/>
        
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>

            <Form.Group controlId='subject'>
              <Form.Label>Subject</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter subject'
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='message'>
              <Form.Label>Message</Form.Label>
              <Form.Control
                type='text'
                as='textarea'
                rows={10}
                placeholder='Enter your message'
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Button type='submit' variant='primary'>
              Send
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  )
}

export default MessageUserScreen
