import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import Product from '../components/Product'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Paginate from '../components/Paginate'
import { listProducts } from '../actions/productActions'
// import { removeFromCart } from '../actions/cartActions'

const PackagesScreen = ({ match }) => {

  localStorage.removeItem('cartItems')
  // Check if the user came from the back button in the browser
  if (window.onpopstate) {
    window.onpopstate = function (event) {
      // alert('You came from the back button')
      // If the user came from the back button, clear local storage for cart items
      localStorage.removeItem('cartItems')
    }
  }
  // if (history.action === 'POP') {
    // alert('You came from the back button')
    // If the user came from the back button, clear local storage for cart items
    // localStorage.removeItem('cartItems')
  // }

  // Clear local storage for cart items
  localStorage.removeItem('cartItems')

  const keyword = match.params.keyword

  const pageNumber = match.params.pageNumber || 1

  const history = useHistory()

  const dispatch = useDispatch()

  // console.log(match.params.id)
  // Log the previous page
  // console.log(history.location.state?.from.pathname)

  // Get the ID paramater from the URL it came from and dispatch the removeFromCart action
  // dispatch(removeFromCart((match.params.id)))

  const productList = useSelector((state) => state.productList)
  const { loading, error, products, page, pages } = productList

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const productsByCategory = products.reduce((categories, product) => {
    if (!categories[product.category]) {
      categories[product.category] = [];
    }
    categories[product.category].push(product);
    return categories;
  }, {});
  
  // console.log(productsByCategory);
  

  useEffect(() => {
    
    // Check if the user is logged in
    if (userInfo) {
      // Clear local storage for cart items
      localStorage.removeItem('cartItems')
      // If the user is logged in, dispatch the listProducts action
      dispatch(listProducts(keyword, pageNumber))
    } else {
      // If the user is not logged in, redirect back to the previous page
      history.push(history.location.state?.from.pathname)
    }
  }, [dispatch, keyword, pageNumber, history, userInfo])

  return (
    <>
     
      <h1>BOOK A RESERVATION</h1>
      <Row>
          <Col sm={12} md={12} lg={12} xl={12}>
              <p>
                Browse through our packages and book your reservation today!
              </p>
              
            </Col>
          </Row>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          
          <Row>
            {/* Filter products by categories */}
            {Object.keys(productsByCategory).map((category) => (
              <Col key={category} sm={12} md={12} lg={12} xl={12}>
                <h3>{category}</h3>
                  {productsByCategory[category].map((product) => (
                    <Col key={product._id} sm={12} md={12} lg={12} xl={12}>
                      <Product product={product} />
                    </Col>
                  ))}
              </Col>
            ))}
          </Row>

          <Paginate
            pages={pages}
            page={page}
            keyword={keyword ? keyword : ''}
          />
        </>
      )}

    </>
  )
}

export default PackagesScreen
