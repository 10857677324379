import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col, ListGroup, Modal } from 'react-bootstrap'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { getUserDetails, updateUser, messageUser } from '../actions/userActions'
import { USER_UPDATE_RESET } from '../constants/userConstants'


const UserEditScreen = ({ match, history }) => {
  const userId = match.params.id

  const [isAnnualMember, setIsAnnualMember] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [middleName, setMiddleName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  // const [password, setPassword] = useState('')
  // const [confirmPassword, setConfirmPassword] = useState('')
  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [country, setCountry] = useState('')
  const [passportNum, setPassportNum] = useState('')

  const [message] = useState(null)
  const [subject, setSubject] = useState('')
  const [messageSuccess, setMessageSuccess] = useState(false)
  const [messageFromAdmin, setMessageFromAdmin] = useState('')
  const [fileUrls, setFileUrls] = useState([]);

  // MODAL STATES
  const [show, setShow] = useState(false);
  const [showUnverify, setShowUnverify] = useState(false);
  const [showActiveMember, setShowActiveMember] = useState(false);
  const [showNonActiveMember, setShowNonActiveMember] = useState(false);
  const handleClose = () => setShow(false);
  const handleCloseUnverify = () => setShowUnverify(false);
  const handleCloseActiveMember = () => setShowActiveMember(false);
  const handleShow = () => setShow(true);
  const handleShowUnverify = () => setShowUnverify(true);
  const handleShowActiveMember = () => setShowActiveMember(true);
  const handleShowNonActiveMember = () => setShowNonActiveMember(true);
  const handleCloseRemoveMember = () => setShowNonActiveMember(false);

  const dispatch = useDispatch()

  const userDetails = useSelector((state) => state.userDetails)
  const { loading, error, user } = userDetails

  const userUpdate = useSelector((state) => state.userUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = userUpdate

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile)
  const { success } = userUpdateProfile

  useEffect(() => {
    async function listFolder(params) {  
      console.log('function running')
      const baseUrl  = "https://api.upload.io";
      const path     = `/v2/accounts/${params.accountId}/folders/list`;
      const entries  = obj => Object.entries(obj).filter(([,val]) => (val ?? null) !== null);
      const query    = entries(params.querystring ?? {})
                        .flatMap(([k,v]) => Array.isArray(v) ? v.map(v2 => [k,v2]) : [[k,v]])
                        .map(kv => kv.join("=")).join("&");
      const response = await fetch(`${baseUrl}${path}${query.length > 0 ? "?" : ""}${query}`, {
        method: "GET",
        headers: Object.fromEntries(entries({
          "Authorization": `Bearer ${params.apiKey}`,
        }))
      });
      const result = await response.json();
      if (Math.floor(response.status / 100) !== 2)
        throw new Error(`Upload API Error: ${JSON.stringify(result)}`);
      return result;
    }

    listFolder({
      accountId: 'kW15b8Y',
      apiKey: 'secret_kW15b8Y9DuYK7BHz3h3cmYDjR3Bx',
      querystring: {
        folderPath: `/uploads/LCC/Users/${user._id}/Documents`,
      },
    }).then(response => {
      console.log(response)
      const fileUrls = response.items.map(item => item.fileUrl);
      setFileUrls(fileUrls);
    }).catch(error => {
      console.error(error);
    });
  }, [user._id]);
      

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: USER_UPDATE_RESET })
      history.push('/admin/userlist')
    } else {
      if (!user.firstName || user._id !== userId) {
        dispatch(getUserDetails(userId))
      } else {
        setFirstName(user.firstName)
        setEmail(user.email)
        setIsAnnualMember(user.isAnnualMember)
      }
    }
  }, [dispatch, history, userId, user, successUpdate])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(updateUser({ _id: userId, firstName, email, isAnnualMember, }))
  }

  const submitHandlerMessage = (e) => {
    e.preventDefault()
    // Set email to user.email
    const email = user.email
    dispatch(messageUser({ email, subject, messageFromAdmin}))
    setMessageSuccess(true)
    // Clear form
    setSubject('')
    setMessageFromAdmin('')
  }

  const submitMemberUpdateHandler = (value) => {
    dispatch(updateUser({ _id: userId, updateType: 'isAnnualMember', isAnnualMember: value, isVerified: user.isVerified }))
  }

  const submitVerifyUpdateHandler = (value) => {
    console.log('submitVerifyUpdateHandler: ', value)
    dispatch(updateUser({ _id: userId, updateType:'isVerified', isVerified: value, isAnnualMember: user.isAnnualMember }))
  }

  return (
    <>
      <Link to='/admin/userlist' className='btn btn-light my-3 mb-5'>
        Go Back
      </Link>
      
      <Tabs
        defaultActiveKey="profile"
        id="justify-tab-example"
        className="mb-3"
        justify
      >
        <Tab eventKey="profile" title="Profile">
          <Row>

            <Col md={6}>
            <h2>Membership Status</h2>
            {/* Check if the user is an annual member, if so, say it */}
            {user.isAnnualMember ? (
              <>
                <p>This user is a member.</p>
                {/* Button that cancels the users membership by setting isAnnualMember to false with dispatch */}
                <Button
                  type='button'
                  variant='danger'
                  onClick={handleShowActiveMember}
                >
                  Cancel Membership
                </Button>
              </>
            ) : (
              <>
                <p>Not an Annual Member</p>
                <Button
                  type='button'
                  variant='primary'
                  // Use onClick to fire dispatch that updates user to annual member
                  onClick={handleShowNonActiveMember}
                >
                  Make Annual Member
                </Button>
              </>
            )}
            <h2 className='mt-50'>User Photo</h2>
            {/* Check if photo exists before displaying it */}
                    {user.userPhoto ? (
                      <img src={user.userPhoto} alt="User Profile" width="200" height="200" />
                    ) : (
                      <p>No photo uploaded.</p>
                    )}
                      
            </Col>

            <Col md={6}>
            <h2>User Profile</h2>
            {message && <Message variant='danger'>{message}</Message>}
            {}
            {success && <Message variant='success'>Profile Updated</Message>}
            {loading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>{error}</Message>
            ) : (
              <>
              <ListGroup variant='flush'>
                <ListGroup.Item>
                  Name: {user.firstName + ' ' + user.middleName + ' ' + user.lastName}
                </ListGroup.Item>
                <ListGroup.Item>
                  User ID: {user._id}
                </ListGroup.Item>
                <ListGroup.Item>
                  Date of Birth: {user.birthday}
                </ListGroup.Item>
                <ListGroup.Item>
                  Email: {email}
                </ListGroup.Item>
                <ListGroup.Item>
                  Phone: {user.phone}
                </ListGroup.Item>
              </ListGroup>
              <br/>
              <ListGroup variant='flush'>
                <ListGroup.Item>
                  Address:<br/> 
                  {user.address},<br/>
                  {user.city}, {user.state} {user.postalCode},<br/>
                  {user.country}
                </ListGroup.Item>
              </ListGroup>
              </>
            )}
            <br/>
            <h2>Verified Status</h2>
            {/* Check if the user is an annual member, if so, say it */}
            {user.isVerified ? (
              <>
                <p>This user is verified.</p>
                {/* Button that cancels the users membership by setting isVerified to false with dispatch */}
                <Button
                  type='button'
                  variant='danger'
                  onClick={handleShowUnverify}
                >
                  Unverify User
                </Button>
              </>
            ) : (
              <>
                <p>Not a verified user.</p>
                <Button
                  type='button'
                  variant='primary'
                  // Use onClick to fire dispatch that updates user to annual member
                  onClick={handleShow}
                >
                  Verify User
                </Button>
              </>
            )}
            {/* <h2 className='mt-50'>update information</h2> */}
            </Col>


          </Row>
        </Tab>
        <Tab eventKey="Documents" title="Documents">
          <h2>View Documents</h2>
          <p>Here are the documents uploaded by the user.</p>
            <ListGroup>
              {fileUrls.map((url, index) => (
                <ListGroup.Item key={index}>
                  <a href={url} target="_blank" rel="noopener noreferrer">
                    File {index + 1}
                  </a>
                </ListGroup.Item>
              ))}
            </ListGroup>
        </Tab>
        {/* <Tab eventKey="bookings" title="Bookings">
          <h2>Bookings</h2>
        </Tab> */}
        <Tab eventKey="message" title="Message User">
          <FormContainer>
            <h1>Message User</h1>
            {loadingUpdate && <Loader />}
            {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
            {messageSuccess && <Message variant='success'>Message sent successfully!</Message>}
            <hr/>
            <p>{user.firstName} {user.lastName}<br/>{user.email}</p>
            <hr/>
            
            {loading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>{error}</Message>
            ) : (
              <Form onSubmit={submitHandlerMessage}>

                <Form.Group controlId='subject'>
                  <Form.Label>Subject</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter subject'
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId='message'>
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    type='text'
                    as='textarea'
                    rows={10}
                    placeholder='Enter your message'
                    value={messageFromAdmin}
                    onChange={(e) => setMessageFromAdmin(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Button type='submit' variant='primary'>
                  Send
                </Button>
              </Form>
            )}
          </FormContainer>
        </Tab>
        <Tab eventKey="settings" title="Information">
          <h2>USER INFORMATION</h2>
            {message && <Message variant='danger'>{message}</Message>}
                {success && <Message variant='success'>Profile Updated</Message>}
                {loading ? (
                  <Loader />
                ) : error ? (
                  <Message variant='danger'>{error}</Message>
                ) : (
                  <>
                  <Form onSubmit={submitHandler} style={{width: '100%'}}>
                    <Row>
                      <Col lg={6} md={6} sm={12}>
                        <Form.Group controlId='firstName'>
                          <Form.Label>First Name</Form.Label>
                          <Form.Control
                            type='name'
                            placeholder='Enter name'
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                          ></Form.Control>
                        </Form.Group>           
                        <Form.Group controlId='middleName'>
                          <Form.Label>Middle Name</Form.Label>
                          <Form.Control
                            type='name'
                            placeholder='Enter name'
                            value={middleName}
                            onChange={(e) => setMiddleName(e.target.value)}
                          ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId='lastName'>
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control
                            type='name'
                            placeholder='Enter last name'
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                          ></Form.Control>
                        </Form.Group>
                      
                        <Form.Group controlId='phone'>
                          <Form.Label>Phone #</Form.Label>
                          <Form.Control
                            type='phone'
                            placeholder='Enter phone #'
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          ></Form.Control>
                        </Form.Group>

                        <Form.Group controlId='email'>
                          <Form.Label>Email Address</Form.Label>
                          <Form.Control
                            type='email'
                            placeholder='Enter email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          ></Form.Control>
                        </Form.Group>

                      </Col>
                      <Col lg={6} md={6} sm={12}>
                        
                        <Form.Group controlId='passportNum'>
                          <Form.Label>Passport #</Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter Passport #'
                            value={passportNum}
                            onChange={(e) => setPassportNum(e.target.value)}
                          ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId='address'>
                          <Form.Label>Address</Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter address'
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                          ></Form.Control>
                        </Form.Group>

                        <Form.Group controlId='city'>
                          <Form.Label>City</Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter your town or city'
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                          ></Form.Control>
                        </Form.Group>

                        <Form.Group controlId='postalCode'>
                          <Form.Label>Postal Code</Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Postal Code'
                            value={postalCode}
                            onChange={(e) => setPostalCode(e.target.value)}
                          ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId='country'>
                          <Form.Label>Country</Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Confirm country'
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Button type='submit' variant='primary'>
                        Update
                      </Button>
                    </Row>
                  </Form>
                  </>
                )}
        </Tab>
      </Tabs>

      {/* Modal to make user a member */}
      <Modal show={showActiveMember} onHide={handleCloseActiveMember} centered>
        <Modal.Body className='dark center text-center'>
          Are you sure you want to make the user a member?
        </Modal.Body>
        <Modal.Footer className='center'>
          <Button variant="primary" onClick={() => submitMemberUpdateHandler(false)}>
            REMOVE MEMBERSHIP
          </Button>
          <Button variant="primary" onClick={handleCloseActiveMember}>
            CANCEL
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal to make user a member */}
      <Modal show={showNonActiveMember} onHide={handleCloseRemoveMember} centered>
        <Modal.Body className='dark center text-center'>
          Are you sure you want to make the user a member?
        </Modal.Body>
        <Modal.Footer className='center'>
          <Button variant="primary" onClick={() => submitMemberUpdateHandler(true)}>
            CONFIRM MEMBERSHIP
          </Button>
          <Button variant="primary" onClick={handleCloseRemoveMember}>
            CANCEL
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for verification */}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body className='dark center text-center'>
          Are you sure you want to verify the user?
        </Modal.Body>
        <Modal.Footer className='center'>
          <Button variant="primary" onClick={() => submitVerifyUpdateHandler(true)}>
            VERIFY
          </Button>
          <Button variant="primary" onClick={handleClose}>
            CANCEL
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal to unverify */}
      <Modal show={showUnverify} onHide={handleCloseUnverify} centered>
        <Modal.Body className='dark center text-center'>
          Are you sure you want to un-verify the user?
        </Modal.Body>
        <Modal.Footer className='center'>
          <Button variant="primary" onClick={() => submitVerifyUpdateHandler(false)}>
            UNVERIFY
          </Button>
          <Button variant="primary" onClick={handleCloseUnverify}>
            CANCEL
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default UserEditScreen
