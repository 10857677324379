import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, ListGroup, Form, Button } from 'react-bootstrap'
import Message from '../components/Message'
import { addToCart, saveflightDetails } from '../actions/cartActions'

const CartScreen = ({ match, location, history }) => {

  // localStorage.removeItem('cartItems')

  const productId = match.params.id
  const dispatch = useDispatch()

  const cart = useSelector((state) => state.cart)
  const { cartItems } = cart

  // const localCart = localStorage.getItem('cartItems')

  // console.log('cart: ', cart)
  // console.log('cartItems: ', cartItems)
  // console.log('localCart: ', localCart)
  // console.log("CART ITEMS RAW:", localStorage.getItem('cartItems'))

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  // Set qtys for each age price
  let [qty] = useState(0)
  const [finalPrice, setFinalPrice] = useState(0)
  const [message, setMessage] = useState('')
  const [messagePassengers, setMessagePassengers] = useState('')
  const [noErrors, setNoErrors] = useState(true)

  const [arrivalAirline, setArrivalAirline] = useState('')
  const [arrivalFlightNo, setArrivalFlightNo] = useState('')
  const [departureAirline, setDepartureAirline] = useState('')
  const [departureFlightNo, setDepartureFlightNo] = useState('')
  const [arrivalDate, setArrivalDate] = useState('')
  const [arrivalTime, setArrivalTime] = useState('')
  const [departureDate, setDepartureDate] = useState('')
  const [departureTime, setDepartureTime] = useState('')

  // const removeFromCartHandler = (id) => {
  //   dispatch(removeFromCart(id))
  // }

  useEffect(() => {

    const tallyPrice = () => {
      let adultPrice = cartItems.reduce((acc, item) => acc + item.priceAdult * item.qtyAdult, 0)
      let youngAdultPrice = cartItems.reduce((acc, item) => acc + item.priceYoungAdult * item.qtyYoungAdult, 0)
      let childPrice = cartItems.reduce((acc, item) => acc + item.priceChild * item.qtyChild, 0)
      let infantPrice = cartItems.reduce((acc, item) => acc + item.priceInfant * item.qtyInfant, 0)
      setFinalPrice(adultPrice + youngAdultPrice + childPrice + infantPrice)
      return finalPrice
    }

    // Check if the user is logged in
    if (!userInfo) {
      history.push('/login')
    }

    // // Check if the user clicked on the back button in the browser
    // if (window.onpopstate) {
    //   window.onpopstate = function (event) {
    //     // If the user came from the back button, clear local storage for cart items
    //     alert('You came from the back button')
    //     dispatch(removeFromCart((match.params.id)))
    //     localStorage.removeItem('cartItems')
    //   }
    // }

    // localStorage.removeItem('cartItems')
    if (productId) {
      dispatch(addToCart(productId, qty))
    }
    tallyPrice()
  }, [dispatch, productId, qty, history, userInfo, match.params.id, finalPrice, cartItems])

  const checkoutHandler = () => {
    // Check if passengers have been selected
    if (cartItems[0].qty === 0) {
      setMessagePassengers('Please select at least one passenger')
      const element = document.getElementById("message_passengers") 
      element.scrollIntoView({behavior: 'smooth'});
      return
    } 
    // Check if the arrival airline field is empty
    if (cartItems[0].name.toLowerCase().includes('arrival') && arrivalAirline === "") {
      setMessage('Please enter the airline')
      setNoErrors(false)
      return 
    }
    // Check if the arrival flight number field is empty
    if (cartItems[0].name.toLowerCase().includes('arrival') && arrivalFlightNo === "") {
      setMessage('Please enter the flight number')
      setNoErrors(false)
      return
    }
    // Check if the item name includes the word 'arrival', if so, check if the arrival date field is empty
    if (cartItems[0].name.toLowerCase().includes('arrival') && arrivalDate === "") {
      setMessage('Please enter the arrival date')
      setNoErrors(false)
      return
    }
    // Check if the item name includes the word 'arrival', if so, check if the arrival time field is empty
    if (cartItems[0].name.toLowerCase().includes('arrival') && arrivalTime === "") {
      setMessage('Please enter the arrival time')
      setNoErrors(false)
      return
    }
    // Check if the departure airline field is empty
    if (cartItems[0].name.toLowerCase().includes('departure') && departureAirline === "") {
      setMessage('Please enter the airline')
      setNoErrors(false)
      return 
    }
    // Check if the departure flight number field is empty
    if (cartItems[0].name.toLowerCase().includes('departure') && departureFlightNo === "") {
      setMessage('Please enter the flight number')
      setNoErrors(false)
      return
    }
    // Check if the item name includes the word 'departure', if so, check if the departure date field is empty
    if (cartItems[0].name.toLowerCase().includes('departure') && departureDate === "") {
      setMessage('Please enter the departure date')
      setNoErrors(false)
      return 
    }
    // Check if the item name includes the word 'departure', if so, check if the departure time field is empty
    if (cartItems[0].name.toLowerCase().includes('departure') && departureTime === "") {
      setMessage('Please enter the departure time')
      setNoErrors(false)
      return
    }

    

    // // Check if the item name includes the word 'round', if so, check if the departure date field is empty
    // if (cartItems[0].name.toLowerCase().includes('round') && departureDate === "") {
    //   setMessage('Please enter the departure date')
    //   setNoErrors(false)
    //   return
    // }
    // // Check if the item name includes the word 'round', if so, check if the departure time field is empty
    // if (cartItems[0].name.toLowerCase().includes('round') && departureTime === "") {
    //   setMessage('Please enter the departure time')
    //   setNoErrors(false)
    //   return
    // }
    // // Check if the item name includes the word 'round', if so, check if the arrival date field is empty
    // if (cartItems[0].name.toLowerCase().includes('round') && arrivalDate === "") {
    //   setMessage('Please enter the arrival date')
    //   setNoErrors(false)
    //   return
    // }
    // // Check if the item name includes the word 'round', if so, check if the arrival time field is empty
    // if (cartItems[0].name.toLowerCase().includes('round') && arrivalTime === "") {
    //   setMessage('Please enter the arrival time')
    //   setNoErrors(false)
    //   return
    // }

    // No errors, proceed to next page
    // If no errors, save flight details and go to next page
    setNoErrors(true)

    if (noErrors) {
      dispatch(saveflightDetails({ arrivalAirline, arrivalFlightNo, departureAirline, departureFlightNo, arrivalDate, arrivalTime, departureDate, departureTime }))
      history.push('/add-passengers')
    } else {
      // If errors, do not save flight details and do not go to next page
      setMessage('Something went wrong. Please try again.')
      // console.log('noErrors:', noErrors)
      return
    }
  }

  return (
    <>
      
      <Row className='trans-bg'>
        
        <Col md={12}>

          {cartItems.length === 0 ? (
            <Message className='mt-20'>
              You don't have any experiences awaiting you yet.<br/> <Link to='/reservations' className='dark'>Go back</Link> and reserve some!
            </Message>
          ) : (
            <ListGroup variant='flush' >
              {cartItems.map((item) => (
                <ListGroup.Item key={item.product}>
                  <br/>
                  <Message variant='info' className="pt-10">Clients are urged to make reservations at least 6-8 hours in advance of service.</Message>
                  <h2 className='mb-20'>SELECTED EXPERIENCE: {item.name}</h2>
                  <h5 id='message_passengers'>Number of Passengers</h5>
                  <p className='mb-50'>Please select the amount of people who will be requiring the service below.<br/>Passenger information will be taken on the next page, age will effect the final cost and will also be verified upon arrival.</p>
          
                  {messagePassengers && <Message variant='danger'>{messagePassengers}</Message>}

                  {/* Adult */}
                  <Row>
                    <Col md={4}>
                      <p className="dark-link">Adult: 18 years and older</p>
                    </Col>
                    <Col md={2} lg={1}>
                      <Form.Control
                        as='select'
                        value={item.qtyAdult}
                        style={{ padding: '0 15px' }}
                        onChange={(e) =>
                          dispatch(
                            addToCart(item.product, Number(e.target.value), item.qtyYoungAdult, item.qtyChild, item.qtyInfant)
                          )
                        }
                      >
                        <option key={0} value={0} defaultValue>0</option>
                        <option key={1} value={1}>1</option>
                        <option key={2} value={2}>2</option>
                        <option key={3} value={3}>3</option>
                        <option key={4} value={4}>4</option>
                        <option key={5} value={5}>5</option>
                      </Form.Control>
                    </Col>
                  </Row>           

                  {/* Young Adult */}
                  <Row>
                    <Col md={4}>
                      <p className="dark-link">Young Adult: 12 to 17 years old</p>
                    </Col>
                    <Col md={2} lg={1}>
                      <Form.Control
                        as='select'
                        value={item.qtyYoungAdult}
                        onChange={(e) =>
                          dispatch(
                            addToCart(item.product, item.qtyAdult, Number(e.target.value), item.qtyChild, item.qtyInfant)
                          )
                          // setQtyChild(Number(e.target.value))
                        }
                        style={{ padding: '0 15px' }}
                      >
                        <option key={0} value={0} defaultValue>0</option>
                        <option key={1} value={1}>1</option>
                        <option key={2} value={2}>2</option>
                        <option key={3} value={3}>3</option>
                        <option key={4} value={4}>4</option>
                        <option key={5} value={5}>5</option>
                      </Form.Control>
                    </Col>
                  </Row>

                  {/* Child */}
                  <Row>
                    <Col md={4}>
                      <p className="dark-link">Child: 5 to 12 years old</p>
                    </Col>
                    <Col md={2} lg={1}>
                      <Form.Control
                        as='select'
                        value={item.qtyChild}
                        onChange={(e) =>
                          dispatch(
                            addToCart(item.product, item.qtyAdult, item.qtyYoungAdult, Number(e.target.value), item.qtyInfant)
                          )
                        }
                        style={{ padding: '0 15px' }}
                      >
                        <option key={0} value={0} defaultValue>0</option>
                        <option key={1} value={1}>1</option>
                        <option key={2} value={2}>2</option>
                        <option key={3} value={3}>3</option>
                        <option key={4} value={4}>4</option>
                        <option key={5} value={5}>5</option>
                      </Form.Control>
                    </Col>
                  </Row>

                  {/* Infant */}
                  <Row>
                    <Col md={4}>
                      <p className="dark-link">Infant: 0 to 4 years old</p>
                    </Col>
                    <Col md={2} lg={1}>
                      <Form.Control
                        as='select'
                        value={item.qtyInfant}
                        onChange={(e) =>
                          dispatch(
                            addToCart(item.product, item.qtyAdult, item.qtyYoungAdult, item.qtyChild, Number(e.target.value))
                          )
                        }
                        style={{ padding: '0 15px' }}
                      >
                        <option key={0} value={0} defaultValue>0</option>
                        <option key={1} value={1}>1</option>
                        <option key={2} value={2}>2</option>
                        <option key={3} value={3}>3</option>
                        <option key={4} value={4}>4</option>
                        <option key={5} value={5}>5</option>
                      </Form.Control>
                    </Col>
                  </Row>



                  {/* Check if item name includes departure */}
                  {item.name === 'Departure' ? (
                    <>
                    <hr className='mt-50'/>
                    <h5 className='mt-50 mb-20'>Departure Details</h5>
                    <p className='mb-50'>Please enter your travel information below. If you have any questions or concerns email us 
                      at <a href="mailto:info@leconciergeclub.com" className='dark'>info@leconciergeclub.com</a>
                    </p>
                    {message && <Message variant='danger'>{message}</Message>}
                    {/* <div className='col-md-6'> */}
                    <Row>
                      <Col md={12} lg={6}>
                        <Form.Group controlId='airline'>
                          <Form.Label>Airline</Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter airline (e.g American Airlines)'
                            value={departureAirline}
                            required
                            onChange={(e) => setDepartureAirline(e.target.value)}
                          ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId='flightNo'>
                          <Form.Label>Flight Number</Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Start with Airline ID (e.g AA 1234)'
                            value={departureFlightNo}
                            required
                            onChange={(e) => setDepartureFlightNo(e.target.value)}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col md={12} lg={6}>
                        <Form.Group controlId='departureTime'>
                          <Form.Label>Departure Date</Form.Label>
                          <Form.Control
                            type='date'
                            placeholder='Enter departure date'
                            value={departureDate}
                            required
                            onChange={(e) => setDepartureDate(e.target.value)}
                          ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId='departureTime'>
                          <Form.Label>Departure Time</Form.Label>
                          <Form.Control
                            type='time'
                            placeholder='Enter departure time'
                            value={departureTime}
                            required
                            onChange={(e) => setDepartureTime(e.target.value)}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    {/* </div> */}
                  </>
                  // Check if item name includes arrival
                  ) : item.name === 'Arrival' ? (
                    <>
                      <hr className='mt-50'/>
                      <h5 className='mt-50 mb-20'>Arrival Details</h5>
                      <p className='mb-50'>Please enter your travel information below. If you have any questions or concerns email us 
                        at <a href="mailto:info@leconciergeclub.com" className='dark'>info@leconciergeclub.com</a>
                      </p>
                      {message && <Message variant='danger'>{message}</Message>}
                      {/* <div className='col-md-6'> */}
                      <Row>
                        <Col md={12} lg={6}>
                          <Form.Group controlId='airline'>
                          <Form.Label>Airline</Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter airline (e.g American Airlines)'
                            value={arrivalAirline}
                            required
                            onChange={(e) => setArrivalAirline(e.target.value)}
                          ></Form.Control>
                          </Form.Group>
                          <Form.Group controlId='flightNo'>
                          <Form.Label>Flight Number</Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Start with Airline ID (e.g AA 1234)'
                            value={arrivalFlightNo}
                            required
                            onChange={(e) => setArrivalFlightNo(e.target.value)}
                          ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md={12} lg={6}>
                          <Form.Group controlId='arrivalDate'>
                            <Form.Label>Arrival Date</Form.Label>
                            <Form.Control
                              type='date'
                              placeholder='Enter arrival date'
                              value={arrivalDate}
                              required
                              onChange={(e) => setArrivalDate(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                          <Form.Group controlId='arrivalTime'>
                            <Form.Label>Arrival Time</Form.Label>
                            <Form.Control
                              type='time'
                              placeholder='Enter arrival time'
                              value={arrivalTime}
                              required
                              onChange={(e) => setArrivalTime(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      {/* </div> */}
                    </>
                  ) : (
                    <>
                      <hr className='mt-50'/>
                      <p className='mb-50'>Please enter your travel information below. If you have any questions or concerns email us 
                        at <a href="mailto:info@leconciergeclub.com" className='dark'>info@leconciergeclub.com</a>
                      </p>
                      {message && <Message variant='danger'>{message}</Message>}
                      {/* <div className='col-md-6'> */}
                      <h3>Arrival</h3>
                      <Row>
                        <Col md={12} lg={6}>
                          <Form.Group controlId='airline'>
                            <Form.Label>Airline</Form.Label>
                            <Form.Control
                              type='text'
                              placeholder='Enter airline (e.g American Airlines)'
                              value={arrivalAirline}
                              required
                              onChange={(e) => setArrivalAirline(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                          <Form.Group controlId='flightNo'>
                            <Form.Label>Flight Number</Form.Label>
                            <Form.Control
                              type='text'
                              placeholder='Start with Airline ID (e.g AA 1234)'
                              value={arrivalFlightNo}
                              required
                              onChange={(e) => setArrivalFlightNo(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md={12} lg={6}>
                          <Form.Group controlId='arrivalDate'>
                            <Form.Label>Arrival Date</Form.Label>
                            <Form.Control
                              type='date'
                              placeholder='Enter arrival date'
                              value={arrivalDate}
                              required
                              onChange={(e) => setArrivalDate(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                          <Form.Group controlId='arrivalTime'>
                            <Form.Label>Arrival Time</Form.Label>
                            <Form.Control
                              type='time'
                              placeholder='Enter arrival time'
                              value={arrivalTime}
                              required
                              onChange={(e) => setArrivalTime(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                          
                        </Col>
                      </Row>

                      <h3>DEPARTURE</h3>
                      <Row>
                        
                        <Col md={12} lg={6}>
                          <Form.Group controlId='airline'>
                            <Form.Label>Airline</Form.Label>
                            <Form.Control
                              type='text'
                              placeholder='Enter airline (e.g American Airlines)'
                              value={departureAirline}
                              required
                              onChange={(e) => setDepartureAirline(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                          <Form.Group controlId='flightNo'>
                            <Form.Label>Flight Number</Form.Label>
                            <Form.Control
                              type='text'
                              placeholder='Start with Airline ID (e.g AA 1234)'
                              value={departureFlightNo}
                              required
                              onChange={(e) => setDepartureFlightNo(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>

                        <Col md={12} lg={6}>
                          <Form.Group controlId='departureTime'>
                            <Form.Label>Departure Date</Form.Label>
                            <Form.Control
                              type='date'
                              placeholder='Enter departure date'
                              value={departureDate}
                              required
                              onChange={(e) => setDepartureDate(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                          <Form.Group controlId='departureTime'>
                            <Form.Label>Departure Time</Form.Label>
                            <Form.Control
                              type='time'
                              placeholder='Enter departure time'
                              value={departureTime}
                              required
                              onChange={(e) => setDepartureTime(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      {/* </div> */}
                    </>
                  )}

                  {/* <Row className='mt-50'>
                  <Col md={6}>
                      <Button
                        type='button'
                        className='btn-block'
                        disabled={cartItems.length === 0}
                        onClick={checkoutHandler}
                      >
                        Proceed To Passengers
                      </Button>
                    </Col>
                  </Row> */}
                 
                  <Row>
                  <Col md={6}>
                  <Button
                    type='button'
                    className='btn-block'
                    disabled={cartItems.length === 0}
                    onClick={checkoutHandler}
                  >
                    Proceed To Passengers
                  </Button>
                    </Col>
                  </Row>
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </Col>
      </Row>
    </>
  )
}

export default CartScreen