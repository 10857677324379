import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Card, Button } from 'react-bootstrap'
// import Rating from '../components/Rating'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Meta from '../components/Meta'
import {
  listProductDetails,
} from '../actions/productActions'
// import { PRODUCT_CREATE_REVIEW_RESET } from '../constants/productConstants'

const ProductScreen = ({ history, match }) => {
  const [qty] = useState(1)
  const dispatch = useDispatch()

  const productDetails = useSelector((state) => state.productDetails)
  const { loading, error, product } = productDetails

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!product._id || product._id !== match.params.id) {
      dispatch(listProductDetails(match.params.id))
      // dispatch({ type: PRODUCT_CREATE_REVIEW_RESET })
    }

    // Check if user is isVerified
    if (!userInfo.isVerified ) {
      // history.push('/verify-pending')
      // console.log(userInfo)
      // console.log('User is not isVerified')
    }
  }, [dispatch, match, history, userInfo, product._id])

  const addToCartHandler = () => {
    history.push(`/cart/${match.params.id}?qty=${qty}`)
  }

  return (
    <>
      <Link className='btn btn-light my-3' to='/'>
        Go Back
      </Link>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <Meta title={product.name} />
          <h3>{product.name}</h3>
          <Row>
            <Col md={6}>
              <Image src={product.image} alt={product.name} fluid />
            </Col>   
            <Col md={6}>
              <Card>
                <ListGroup variant='flush'>

                  <ListGroup.Item>
                    <Row className='no-margin'>
                      <Col>Status:</Col>
                      <Col>
                        {product.countInStock > 0 ? 'Available' : 'Currently not available'}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                

                  <hr/>

                  <ListGroup.Item>
                    <Button
                      onClick={addToCartHandler}
                      className='btn-block'
                      type='button'
                    >
                      Reserve
                    </Button>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <ListGroup variant='flush'>
                <ListGroup.Item className='no-padding'>
                  {product.description}
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
          
        </>
      )}
    </>
  )
}

export default ProductScreen
