import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainerFull from '../components/FormContainerFull'
import { register } from '../actions/userActions'

const RegisterScreen = ({ location, history }) => {

  const [firstName, setFirstName] = useState('')
  const [middleName, setMiddleName] = useState('')
  const [lastName, setLastName] = useState('')
  const [birthday, setBirthday] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [country, setCountry] = useState('')

  const [passportNum, setPassportNum] = useState('')
  const [message, setMessage] = useState('')
  const [posts, setPosts] = useState(null);

  const [noErrors, setNoErrors] = useState(false)

  const dispatch = useDispatch()

  const userRegister = useSelector((state) => state.userRegister)
  const { loading, error, userInfo } = userRegister

  // const redirect = location.search ? location.search.split('=')[1] : '/'
  const redirect = location.search ? location.search.split('=')[1] : '/verify-pending'

  useEffect(() => {
    if (userInfo) {
      history.push(redirect)
    }

    async function loadPosts() {
      const response = await fetch('https://leconcierge.dreamhosters.com/wp-json/wp/v2/pages/173');
      if(!response.ok) {
        // oups! something went wrong
        return;
      }
      
      const posts = await response.json();
      console.log("Rates:", posts)
      setPosts(posts);
      // console.log(posts)
    }
  
    loadPosts();

  }, [history, userInfo, redirect])

  if (posts === null) return null

  // Verify users are 18 years old
  const verifyAge = (e) => {
    // console.log('Fired age verification')
    e.preventDefault()
    const today = new Date()
    const birthDate = new Date(birthday)
    let age = today.getFullYear() - birthDate.getFullYear()
    const m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--
    }
    if (age < 18) {
      return false
    } else {
      return true
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()
    // alert('Fired submit handler')
    // console.log('Fired submit handler')
    // Check if name fields are empty
    if (firstName === '' || lastName === '') {
      setMessage('Please enter your first and last name')
      setNoErrors(false)
      return
    } else {
      setNoErrors(true)
    }
    // Check if birthday field is empty
    if (birthday === '') {
      setMessage('Please enter your birthday')
      setNoErrors(false)
      return
    } else {
      setNoErrors(true)
    }
    // Verify users are 18 years old
    if (!verifyAge(e)) {
      setMessage('You must be 18 years old to register')
      setNoErrors(false)
      return
    } else {
      setNoErrors(true)
    }

    // Check if email field is empty
    if (email === '') {
      setMessage('Please enter your email')
      setNoErrors(false)
      return
    } else {
      setNoErrors(true)
    }

    // Check that phone number is at least 10 characters
    if (phone.length < 10) {
      setMessage('Phone number must be at least 10 characters')
      setNoErrors(false)
      return
    } else {
      setNoErrors(true)
    }

    // Check if password fields are empty and the same
    if (password === '' || confirmPassword === '') {
      setMessage('Please enter your password')
      setNoErrors(false)
      return
    } else if (password !== confirmPassword) {
      setMessage('Passwords do not match')
      setNoErrors(false)
      return
    } else if (password.length < 6) {
      setMessage('Password must be at least 6 characters')
      setNoErrors(false)
      return
    } else {
      setNoErrors(true)
    }

    // Check if address fields are empty
    if (address === '' || city === '' || postalCode === '' || country === '') {
      setMessage('Please enter your address')
      setNoErrors(false)
      return
    } else {
      setNoErrors(true)
    }
    
    // Check that postal code is at least 5 characters
    if (postalCode.length < 5) {
      setMessage('Postal code must be at least 5 characters')
      setNoErrors(false)
      return
    }
    
    // Check that passport number is at least 8 characters
    if (passportNum.length < 8) {
      setMessage('Passport number must be at least 8 characters')
      setNoErrors(false)
      return
    } else {
      setNoErrors(true)
    }

    if (noErrors) {
      // console.log('No errors')
      dispatch(register(
        firstName,
        middleName,
        lastName,
        birthday,
        address,
        city,
        postalCode,
        country,
        passportNum,
        phone,
        email,
        password
      ))
      history.push(redirect)
    }
  }

  return (
  <Row>
    <Col md={8}>
      <FormContainerFull>
        <h1>REGISTER</h1>
        {message && <Message variant='danger'>{message}</Message>}
        {error && <Message variant='danger'>{error}</Message>}
        {loading && <Loader />}

        <Form onSubmit={submitHandler} className='mt-50'>
          <h4>Personal Information</h4>
          <Form.Group controlId='FirstName'>
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type='name'
              placeholder='Enter first name'
              value={firstName}
              // required
              onChange={(e) => setFirstName(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId='MiddleName'>
            <Form.Label>Middle Name</Form.Label>
            <Form.Control
              type='name'
              placeholder='Enter middle name'
              value={middleName}
              onChange={(e) => setMiddleName(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId='LastName'>
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type='name'
              placeholder='Enter last name'
              value={lastName}
              // required
              onChange={(e) => setLastName(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId='Birthday'>
            <Form.Label>Date of Birth</Form.Label>
            <Form.Control
              type='date'
              placeholder='Enter birthday'
              value={birthday}
              // required
              onChange={(e) => setBirthday(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='email'>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type='text'
              placeholder='Enter your email'
              value={email}
              // required
              onChange={(e) => setEmail(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='phone'>
            <Form.Label>Phone</Form.Label>
            <Form.Control
              type='text'
              placeholder='Enter phone number'
              value={phone}
              // required
              onChange={(e) => setPhone(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <h4 className='mt-50'>Password</h4>

          <Form.Group controlId='password'>
            <Form.Label>Password</Form.Label>
            <Form.Control
              type='password'
              placeholder='Enter password'
              value={password}
              // required
              onChange={(e) => setPassword(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='confirmPassword'>
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type='password'
              placeholder='Confirm password'
              // required
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            ></Form.Control>
          </Form.Group>

          {/* <hr/> */}

            <h4 className='mt-50'>Address</h4>
            <Form.Group controlId='address'>
              <Form.Label>Address</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter address'
                value={address}
                // required
                onChange={(e) => setAddress(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='city'>
              <Form.Label>City</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter city'
                value={city}
                // required
                onChange={(e) => setCity(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='postalCode'>
              <Form.Label>Postal Code</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter postal code'
                value={postalCode}
                // required
                onChange={(e) => setPostalCode(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='country'>
              <Form.Label>Country</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter country'
                value={country}
                // required
                onChange={(e) => setCountry(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <h4 className='mt-50'>Passport</h4>
            <Form.Group controlId='passportNum'>
              <Form.Label>Passport #</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter Passport Number'
                value={passportNum}
                onChange={(e) => setPassportNum(e.target.value)}
              ></Form.Control>
            </Form.Group>


          <Button type='submit' variant='primary' className='mt-50'>
            Register
          </Button>
        </Form>

        <Row className='py-3'>
          <Col>
            Have an account?{' '}
            <Link to={redirect ? `/login?redirect=${redirect}` : '/login'}>
              Login
            </Link>
          </Col>
        </Row>
      </FormContainerFull>
    </Col>
    <Col md={4}>
        <h1>Information</h1>
        <div dangerouslySetInnerHTML={{__html: posts.content.rendered}} />
       </Col>
  </Row>
  )
}

export default RegisterScreen
