import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

// Images
// import firstPicture from "../images/1.jpg"
import feather from "../images/art/LCCFeather.svg"
import SecLogo from "../images/art/LCCSecLogo.svg"
import facebook from "../images/facebook-32.png"
import twitter from "../images/twitter-48.png"
import instagram from "../images/Instagram-48.png"

const Footer = () => {
  return (
    <footer>
      <Container>
      <hr/>
          <Row>
            <Col sm={12} md={6} lg={9} xl={9}>
              <ul style={{listStyleType: 'none', paddingLeft: 0}}>
                <li><a href='/terms-and-conditions'>Terms and Conditions</a></li>
                <li><a href='/privacy-policy'>Privacy Policy</a></li>
                <li><a href='/faqs'>Frequently Asked Questions</a></li>
                <li><a href='/refund-policy'>Refund Policy</a></li>
              </ul>
            </Col>
            <Col sm={12} md={6} lg={3} xl={3}>
              <img src={feather} alt='feather logo' width={125} className="footerFeather"/>
            </Col>
          </Row>
          <hr/>
        <Row className='justify-content-between justify-content-center-md'>
          <Col sm={12} md={6} lg={8} xl={8}>
            <img src={SecLogo} width="350" alt="secondary logo" className='footer-logo mb-20-md' />
          </Col>
          <Col sm={12} md={6} lg={4} xl={4} className="justify-content-end justify-content-center-md">
            <a href="https://www.facebook.com/Le-Concierge-Club-304626363589547" rel="noopener noreferrer" target="_blank"><img src={facebook} width="32" alt="facebook" height="32" style={{marginRight: "10px"}} /></a>
            <a href="https://twitter.com/LeConciergeClub" rel="noopener noreferrer" target="_blank"><img src={twitter} width="32" alt="twitter" height="32" /></a>
            <a href="https://instagram.com/LeConciergeClub" rel="noopener noreferrer" target="_blank"><img src={instagram} width="32" alt="instagram" height="32" /></a>
          </Col>
        </Row>
        <hr/>
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <p>Royalty &bull; Respect &bull; Honour</p>
          </Col>
          <Col sm={12} md={6} lg={6} xl={6} className="text-right">
            <p>Beauty &bull; Love &bull; Passion</p>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6} lg={6} xl={6} className='py-3 copyright'>Copyright &copy; LCC. Kotoka International Airport, Accra, Ghana</Col>
          <Col sm={12} md={6} lg={6} xl={6} className='py-3 copyright text-right'>
            <a href="https://consilio.studio" target="_blank" rel="noopener noreferrer" className='mr-10'>Designed by Consilio</a>
            <a href="https://seasonsix.co" target="_blank" rel="noopener noreferrer">Built by S6</a>
          </Col>  
        </Row>
          
        
      </Container>
    </footer>
  )
}

export default Footer
