import React, { useEffect, useState } from 'react'
// import { Link } from 'react-router-dom'
import { Button, Row, Col, ListGroup, Image, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
// import CheckoutSteps from '../components/CheckoutSteps'
import { createBooking } from '../actions/bookingActions'
import { getSettings } from '../actions/settingsActions'
import { BOOKING_CREATE_RESET } from '../constants/bookingConstants'
import { USER_DETAILS_RESET } from '../constants/userConstants'

const PlaceBookingScreen = ({ history }) => {
  const dispatch = useDispatch()

  const cart = useSelector((state) => state.cart)
  // const passengers = useSelector((state) => state.passengerDetails)
  const passengers = JSON.parse(localStorage.getItem('passengerDetails'))

  // Get exchange rate from state
  const exchangeRateState = useSelector((state) => state.exchangeRate)
  const { loading: loadingRate, error: errorRate, settings } = exchangeRateState
  // const exchangeRate = settings.exchangeRate
  // console.log('exchangeRate: ', exchangeRate)
  console.log('exchangeRateState: ', settings)

  // if (!cart.paymentMethod) {
  //   history.push('/payment')
  // }
  if (!cart.flightDetails) {
    history.push('/flight-details')
  } 
  
  //   Calculate prices
  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2)
  }

  cart.itemsPrice = addDecimals(
    cart.cartItems.reduce((acc, item) => acc + item.priceAdult * item.qty, 0)
  )
  // cart.taxPrice = addDecimals(Number((0.1925 * cart.itemsPrice).toFixed(2)))
  cart.totalPrice = (
    Number(cart.itemsPrice)
  ).toFixed(2)

  const bookingCreate = useSelector((state) => state.bookingCreate)
  const { booking, success, error } = bookingCreate

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const isAnnualMember = useState(userInfo.isAnnualMember)
  // const exchangeRate = useState(settings.exchangeRate)

  useEffect(() => {
    dispatch(getSettings())   

    if (success) {
      history.push(`/booking/${booking._id}`)
      dispatch({ type: USER_DETAILS_RESET })
      dispatch({ type: BOOKING_CREATE_RESET })
    }
    // eslint-disable-next-line
  }, [dispatch, history, success])

  const placeBookingHandler = () => {
    dispatch(
      createBooking({
        bookingItems: cart.cartItems,
        flightDetails: cart.flightDetails,
        passengers: cart.passengers,
        paymentMethod: cart.paymentMethod,
        itemsPrice: cart.itemsPrice,
        // taxPrice: cart.taxPrice,
        totalPrice: cart.cartItems[0].totalPrice,
      })
    )
  }

  console.log('isAnnualMember: ', isAnnualMember)
  console.log('cart.cartItems: ', cart.cartItems)

  return (
    <>
    {loadingRate ? (
        <Loader />
      ) : errorRate ? (
        <Message variant='danger'>{errorRate}</Message>
      ) : (
        <>
          <Row className='trans-bg'>
            <Col md={12}>
            <h2 className='dark'>Booking Summary</h2>
            {/* Check if the user is an Annual Member is true */}
            {userInfo.isAnnualMember && (
              <p>Please review the details of your booking before confirming. Once you have confirmed you will recieve an email from us as confirmation and you will be sent to a page where you can
                view the details for your booking.
                <br/>
                <br/>
                If you have any questions please contact us at <a href='mailto:info@leconciergeclub.com' className='dark'>info@leconciergeclub.com</a> or call us at <a href='tel:+1-647-557-5555' className='dark'>+1-647-557-5555</a>.
              </p>
            )}
            {!userInfo.isAnnualMember && (
              <p>Please review the details of your booking before confirming. Once you have confirmed you will recieve an email from us as confirmation and you will be sent to a page where you can
              pay for your booking.
              <br/>
              <br/>
              If you have any questions please contact us at <a href='mailto:info@leconciergeclub.com' className='dark'>info@leconciergeclub.com</a> or call us at <a href='tel:+1-647-557-5555' className='dark'>+1-647-557-5555</a>.
              </p>
            )}
            </Col>
            <Col md={8}>
              <ListGroup variant='flush'>
                <ListGroup.Item>
                  <h2>Booking Details</h2>
                  <div className='flex'>
                    <Image
                      src={cart.cartItems[0].image}
                      alt={cart.cartItems[0].name}
                      width='200px'
                      height='auto'
                      className='mr-3'
                      fluid
                      rounded
                    />
                    <p>
                      Service: {cart.cartItems[0].name}<br/>
                      {/* Airline: {cart.flightDetails.airline}<br/>
                      Flight No: {cart.flightDetails.flightNo}<br/> */}
                      {/* Check if arrival airline is empty, if not show info */}
                      {cart.flightDetails.arrivalAirline && (
                        <>
                          Arrival Airline: {cart.flightDetails.arrivalAirline}<br/>
                        </>
                      )}
                      {/* Check if arrival flight no is empty, if not show info */}
                      {cart.flightDetails.arrivalFlightNo && (
                        <>
                          Arrival Flight No: {cart.flightDetails.arrivalFlightNo}<br/>
                        </>
                      )}
                      {/* Check if departure airline is empty, if not sow info */}
                      {cart.flightDetails.departureAirline && ( 
                        <>
                          Departure Airline: {cart.flightDetails.departureAirline}<br/>
                        </>
                      )}
                      {/* Check if departure flight no is empty, if not show info */}
                      {cart.flightDetails.departureFlightNo && (
                        <>
                          Departure Flight No: {cart.flightDetails.departureFlightNo}<br/>
                        </>
                      )}
                      {/* Check if arrival date is empty, if not show info */}
                      {cart.flightDetails.arrivalDate && (
                        <>
                          Arrival Date: {cart.flightDetails.arrivalDate}<br/>
                        </>
                      )}
                      {/* Check if arrival time is empty, if not show info */}
                      {cart.flightDetails.arrivalTime && (
                        <>
                          Arrival Time: {cart.flightDetails.arrivalTime}<br/>
                        </>
                      )}
                      {/* Check if departure date is empty, if not show info */}
                      {cart.flightDetails.departureDate && (
                        <>
                          Departure Date: {cart.flightDetails.departureDate}<br/>
                        </>
                      )}
                      {/* Check if departure time is empty, if not show info */}
                      {cart.flightDetails.departureTime && (
                        <>
                          Departure Time: {cart.flightDetails.departureTime}<br/>
                        </>
                      )}
                    </p>
                  </div>
                </ListGroup.Item>

                <hr/>

                <ListGroup.Item>
                  <h2>Passenger Details</h2>
                  
                  {passengers.map((passengerDetail, index) => (
                    <>
                      <ListGroup.Item key={index}>
                        <p className='mb-0'>Name: {passengerDetail.name}</p>
                        <p className='mb-0'>Date of Birth: {passengerDetail.dateOfBirth}</p>
                        <p className='mb-0'>Nationality: {passengerDetail.nationality}</p>
                        <p className='mb-0'>Passport #: {passengerDetail.passportNumber}</p>
                      </ListGroup.Item>
                      <br/>
                    </>
                  ))}
                </ListGroup.Item>

                <hr/>

              </ListGroup>
            </Col>
            <Col md={4}>
              <Card>
                <ListGroup variant='flush'>
                  {userInfo.isAnnualMember && (
                    <ListGroup.Item>
                      <h2>CONFIRM BOOKING</h2>
                      <Button
                        type='button'
                        className='btn-block'
                        disabled={cart.cartItems === 0}
                        onClick={placeBookingHandler}
                      >
                        CONFIRM
                      </Button>
                    </ListGroup.Item>
                  )}
                  {!userInfo.isAnnualMember && (
                    <>
                      <ListGroup.Item>
                        <h2>Cost</h2>
                        <h5>Adults: {cart.cartItems[0].qtyAdult} (${cart.cartItems[0].priceAdult} each)</h5>
                        <h5>Young Adults: {cart.cartItems[0].qtyYoungAdult} (${cart.cartItems[0].priceYoungAdult} each)</h5>
                        <h5>Children: {cart.cartItems[0].qtyChild} (${cart.cartItems[0].priceChild} each)</h5>
                        <h5>Infants: {cart.cartItems[0].qtyInfant} (${cart.cartItems[0].priceInfant} each)</h5>
                        {/* <h5>Subtotal: ${cart.cartItems[0].totalPrice}</h5> */}
                        <br/>
                        {/* <h5><strong>VAT(19.25%):  ${ (19.25 / 100) * cart.cartItems[0].totalPrice}</strong></h5> */}
                        {/* <h5>Total: ${cart.cartItems[0].totalPrice + (19.25 / 100) * cart.cartItems[0].totalPrice.toFixed(2)}</h5> */}
                        <h5>Total: USD ${cart.cartItems[0].totalPrice}</h5>
                        {/* Check if exchangeRate has loaded, if so show the cost in GHS */}
                        { settings && settings.exchangeRate ? (
                        // {settings.exchangeRate && (
                          <>
                            <h5>Total: GHS {(cart.cartItems[0].totalPrice * settings.exchangeRate).toFixed(2)}</h5>
                          </>
                        ) : (
                          <>
                            <h5>Total: GHS 0.00</h5>
                          </>
                        )}
                        <p>VAT is included in the total cost.</p>

                      </ListGroup.Item>

                      <ListGroup.Item>
                        {error && <Message variant='danger'>{error}</Message>}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Button
                          type='button'
                          className='btn-block'
                          disabled={cart.cartItems === 0}
                          onClick={placeBookingHandler}
                        >
                          Place Booking
                        </Button>
                      </ListGroup.Item>  
                    </>
                  )}
                  
                </ListGroup>
              </Card>
            </Col>
          </Row>
        </> 
      )}
    </>
  )
}

export default PlaceBookingScreen
