import React, { useState, useEffect } from 'react'
import { Form, Button, Tab, Tabs } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { updateExchangeRate } from '../actions/settingsActions'
// import { USER_UPDATE_RESET } from '../constants/userConstants'

import { getSettings } from '../actions/settingsActions'
import { registerAdmin } from '../actions/userActions'

const AdminSettingsScreen = ({ match, history }) => {

  const dispatch = useDispatch()

  // Get exchange rate from state
  const exchangeRateState = useSelector((state) => state.exchangeRate)
  const { loading, error, settings } = exchangeRateState

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const [newExchangeRate, setNewExchangeRate] = useState('')

  // New Admin State
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  // Message State
  // const [subject, setSubject] = useState('')
  // const [message, setMessage] = useState('')

  const [errorMessage, setErrorMessage] = useState('')


  useEffect(() => {
    // Get settings from state and set to local state
    dispatch(getSettings()) 
  }, [dispatch])

  const exchangeRateUpdate = (e) => {
    e.preventDefault()
    console.log('newExchangeRate: ', newExchangeRate)
    dispatch(updateExchangeRate(newExchangeRate))
  }

  const submitHandler = (e) => {
    e.preventDefault()
    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match')
    } else if (email === '' || password === '' || firstName === '' || lastName === ''){
      setErrorMessage('Please fill out all fields')
    } else if (password.length < 6){
      setErrorMessage('Password must be at least 6 characters')
    } else {
      dispatch(registerAdmin(firstName, lastName, email, password))
    }
    // dispatch(updateUser({ _id: userId, name, email, isAnnualMember }))
  }

  return (
    <>
      {/* <Link to='/admin/userlist' className='btn btn-light my-3'>
        Go Back
      </Link> */}
      <h1>ADMIN SETTINGS</h1>
      <Tabs
        defaultActiveKey="exchange"
        id="fill-tab-example"
        className="mb-3 mt-5"
        fill
      >
      <Tab eventKey="exchange" title="Exchange Rate">
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <p>Here is where you can update the desired exchange rate you would like to use.</p>
          <p>Once you have updated the rate, click save.</p>
          <br/>
          <div className='my-3'>
              {/* Check if settings.exchange is defined, if so, show it */}
              {settings && settings.exchangeRate ? (
                <p>Current Rate:&nbsp;{settings.exchangeRate}%</p>
              ) : (
                <p>0</p>
              )}
              <h5>NEW RATE:</h5>
              <input type="number" name="exchangeRate" value={newExchangeRate} onChange={(e) => setNewExchangeRate(e.target.value)} />
              <br/>
              <br/>
              <Button onClick={exchangeRateUpdate}>
                SAVE
              </Button>
            </div>
          </>
        )}
      </Tab>
      {/* Check if the user isSuperAdmin === 'true' before showing tab */}
      {userInfo && userInfo.isSuperAdmin && (
        <Tab eventKey="newAdmin" title="Create Admin">
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <>
          <p>Fill in the information below to create a new adminsitrative account</p>
          <hr/>
          <br/>
          {errorMessage && <Message variant='danger'>{errorMessage}</Message>}
          <Form onSubmit={submitHandler}>
                <Form.Group controlId='firstName'>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type='name'
                    placeholder='Enter name'
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                
                <Form.Group controlId='lastName'>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type='name'
                    placeholder='Enter last name'
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId='email'>
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    type='email'
                    placeholder='Enter email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId='password'>
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type='password'
                    placeholder='Enter password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId='confirmPassword'>
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type='password'
                    placeholder='Confirm password'
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Button type='submit' variant='primary'>
                  CREATE ADMIN
                </Button>
              </Form>
            </>
          )}
        </Tab>
      )}
      {/* <Tab eventKey="welcome" title="Welcome Email">
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <p>This is the message that gets sent out to a new user once they have created an account. You can edit the subject and message below. Once you have made your changes, click update.
          </p>
          <p>
            At the end of the email the users information will be included and sent to them in the following format:
            <br/>
            Name: John Adams
            <br/>
            Email: john@email.com
            <br/>
            ID: 123456789 
          </p>
          
          <hr/>
          <br/>
          <Form onSubmit={submitHandler}>
          {settings && settings.welcomeSubject ? (
            <Form.Group controlId='subject'>
                  <Form.Label>Subject</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter subject'
                    value={settings.welcomeSubject}
                    onChange={(e) => setSubject(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                ) : (
                  <Form.Group controlId='subject'>
                  <Form.Label>Subject</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter subject'
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                )}
                {settings && settings.welcomeEmail ? (
                <Form.Group controlId='message'>
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    type='text'
                    as='textarea'
                    rows={10}
                    placeholder='Enter your message'
                    value={settings.welcomeEmail}
                    onChange={(e) => setMessage(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                ) : (
                  <Form.Group controlId='message'>
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      type='text'
                      as='textarea'
                      rows={10}
                      placeholder='Enter your message'
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                )}

                <Button type='submit' variant='primary'>
                  SAVE
                </Button>
          </Form>
        </>
      )}
      </Tab>
      <Tab eventKey="confirmation" title="Confirmation Email">
      <p>This is the message that gets sent out to a user once they have paid for their service.</p>
      <p>
      
            At the end of the email the users information will be included and sent to them in the following format:
            <br/>
            Name: John Adams
            <br/>
            Email: john@email.com
            <br/>
            Booking: https://www.leconcierge.com/booking/123456789
          </p>
      
      <hr/>
        <br/>
        <Form onSubmit={submitHandler}>
        {settings && settings.confirmationSubject ? (
            <Form.Group controlId='subject'>
                  <Form.Label>Subject</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter subject'
                    value={settings.confirmationSubject}
                    onChange={(e) => setSubject(e.target.value)}
                  ></Form.Control>
                </Form.Group>
        ) : (
          <Form.Group controlId='subject'>
                  <Form.Label>Subject</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter subject'
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                  ></Form.Control>
                </Form.Group>
        )}
                {settings && settings.confirmationEmail ? (
                <Form.Group controlId='message'>
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    type='text'
                    as='textarea'
                    rows={10}
                    placeholder='Enter your message'
                    value={settings.confirmationEmail}
                    onChange={(e) => setMessage(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                ) : (
                  <Form.Group controlId='message'>
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      type='text'
                      as='textarea'
                      rows={10}
                      placeholder='Enter your message'
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                )}

                <Button type='submit' variant='primary'>
                  UPDATE
                </Button>
          </Form>
      </Tab>
      <Tab eventKey="cancellation" title="Cancellation Email">
        <p>This is the message that gets sent out to a user once a service has been cancelled.</p>
        <p>
      
      At the end of the email the users information will be included and sent to them in the following format:
      <br/>
      Name: John Adams
      <br/>
      Email: john@email.com
      <br/>
      Booking: https://www.leconcierge.com/booking/123456789
    </p>

        <hr/>
        <br/>
        <Form onSubmit={submitHandler}>
        {settings && settings.cancellationSubject ? (
            <Form.Group controlId='subject'>
                  <Form.Label>Subject</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter subject'
                    value={settings.cancellationSubject}
                    onChange={(e) => setSubject(e.target.value)}
                  ></Form.Control>
                </Form.Group>
        ) : (
          <Form.Group controlId='subject'>
          <Form.Label>Subject</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter subject'
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          ></Form.Control>
        </Form.Group>
        )}
                {settings && settings.cancellationEmail ? (
                <Form.Group controlId='message'>
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    type='text'
                    as='textarea'
                    rows={10}
                    placeholder='Enter your message'
                    value={settings.cancellationEmail}
                    onChange={(e) => setMessage(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                ) : (
                  <Form.Group controlId='message'>
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      type='text'
                      as='textarea'
                      rows={10}
                      placeholder='Enter your message'
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                )}

                <Button type='submit' variant='primary'>
                  UPDATE
                </Button>
          </Form>
      </Tab> */}
    </Tabs>
    </>
  )
}

export default AdminSettingsScreen
