import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import { Row, Col, Modal, Button } from 'react-bootstrap'
import { useLocation, useHistory } from 'react-router-dom';
import Meta from '../components/Meta'

import images from '../components/images'
import ImageCarousel from '../components/ImageCarousel'

// Videos
import video from "../images/video/INTERIORDESIGN.mp4"


const HomeScreen = ({ match }) => {

  const [posts, setPosts] = useState(null);

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const location = useLocation()
  const history = useHistory()

  const handleShow = (e) => {
    e.preventDefault();
    // Check if user is logged in, if so redirect to reservation page
    if (userInfo) {
      setShow(false);
      history.push('/reservations')
    } else {
      setShow(true);
      // Don't redirect to reservations page
    }

    // Check the url to see if the user is on the profile page
    // If so, add the active class to the profile link
    if (location.pathname === '/profile') {
      document.querySelector('.dropdown-toggle').classList.add('active')
    }
  }

  const handleMember = () => {
    setShow(false);
    window.location.href = '/login'
  }

  const handleNonMember = () => {
    setShow(false);
    history.push('/register')
  }

    useEffect(() => {
      async function loadPosts() {
        const response = await fetch('https://leconcierge.dreamhosters.com/wp-json/wp/v2/pages/2');
        if(!response.ok) {
          // oups! something went wrong
          return;
        }
        
        const posts = await response.json();
        setPosts(posts);
      }
    
      loadPosts();
   }, [])

   if (posts === null) return null


  return (
    <>
      <Meta />
     
          <ImageCarousel images={images} />
          <Row className="justify-content-center text-center mt-50">
            {/* Introductory Text Area */}
            <Col sm={12} md={12} lg={8} xl={8}>
              <h2 className='large-h2'><div dangerouslySetInnerHTML={{__html: posts.acf.intro_header}} /></h2>
              <p dangerouslySetInnerHTML={{__html: posts.acf.intro_body_text}} />
              <a href="/reservations" onClick={handleShow}>
                <button>Reserve</button>
              </a>
            </Col>

            {/* Video */}
            <Col sm={12} md={12} lg={12} xl={12} className="mt-50 justify-content-center text-center">
              <div className='video mt-50 mb-50'>
                <video width="100%" height="auto" controls>
                  <source src={video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>

            </Col>
          </Row>

          {/* Three Column Text Area with Images */}
          <Row className='mt-50'>
            <Col sm={12} md={12} lg={4} xl={4} className="mb-50 text-center-mobile">
              <img src={posts.acf.first_column_image} alt='Descriptive text' width={300} className="crop-200 mb-10" />
              <p dangerouslySetInnerHTML={{__html: posts.acf.first_column_text}} />
            </Col>
            <Col sm={12} md={12} lg={4} xl={4} className="mb-50 text-center-mobile">
              <img src={posts.acf.second_column_image} alt='Descriptive text' width={300} className="crop-200 mb-10" />
              <p dangerouslySetInnerHTML={{__html: posts.acf.second_column_text}} />
            </Col>
            <Col sm={12} md={12} lg={4} xl={4} className="mb-50 text-center-mobile">
              <img src={posts.acf.third_column_image} alt='Descriptive text' width={300} className="crop-200 mb-10" />
              <p dangerouslySetInnerHTML={{__html: posts.acf.third_column_text}} />
            </Col>
          </Row>

          <hr/>

          <Row>
            <Col sm={12} md={12} lg={12} xl={12} className="text-center">
              <img src={posts.acf.quote_image} alt='Awadwa kyinkyin a onya ab3 wC' width="100%" className="mb-10 mt-50" style={{maxWidth: 400}}/>
              {/* <h3 className='quote'>"Awadwa kyinkyin a onya ab<span className='twi_3'>3</span> w<span className='twi_C'>C</span>"</h3>
              <p style={{textAlign: "center"}}>"Nothing ventured, nothing gained."</p> */}
            </Col>
          </Row>

          <Modal show={show} onHide={handleClose} centered>
            <Modal.Body className='dark center text-center'>
              You must have an account to reserve a service. 
            </Modal.Body>
            <Modal.Footer className='center'>
              <Button variant="primary" onClick={handleNonMember}>
                CREATE AN ACCOUNT
              </Button>
              <Button variant="primary" onClick={handleMember}>
                EXISTING ACCOUNT
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Two Column Text Area with Image & Button */}
          {/* <Row>
            <Col sm={12} md={12} lg={6} xl={6} className="text-center-mobile">
              <h2 dangerouslySetInnerHTML={{__html: posts.acf.fourth_intro_header}} />
              <p dangerouslySetInnerHTML={{__html: posts.acf.fourth_column_text}} />
              <a href="/reservations">
                <button className='mb-50-mobile'>Reserve</button>
              </a>
            </Col>
            <Col sm={12} md={12} lg={6} xl={6}>
              <img src={posts.acf.fourth_column_image} alt="Video" align="center" width="100%" />
            </Col>
          </Row> */}
          
        </>
  )
}

export default HomeScreen
