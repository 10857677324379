import React, { useState, useEffect } from 'react'
import { PaystackButton } from 'react-paystack'
import { Link } from 'react-router-dom'
import { Row, Col, ListGroup, Image, Button, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { getBookingDetails, deliverBooking, cancelBooking } from '../actions/bookingActions'
import { getSettings } from '../actions/settingsActions'
import { getUserDetails } from '../actions/userActions'
import { set } from 'mongoose'

const BookingScreen = ({ match, history }) => {

  const dispatch = useDispatch()
  const bookingId = match.params.id

  const bookingDetails = useSelector((state) => state.bookingDetails)
  const { booking, loading, error } = bookingDetails

  const bookingDeliver = useSelector((state) => state.bookingDeliver)
  const { success: successDeliver } = bookingDeliver

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  // Get exchange rate from state
  const exchangeRateState = useSelector((state) => state.exchangeRate)
  const { settings } = exchangeRateState

  // PAYSTACK
  // const publicKey = "pk_test_50d9017f52712370a4fe54d1584ddba7de18d3f4"
  const publicKey = "pk_live_e18042c867f02690a57361183fe916eed6940dfb"
  const [amount, setAmount] = useState(booking && booking.totalPrice)
  const [rate, setRate] = useState(settings && settings.exchangeRate)

  const [id] = useState(bookingId)
  const [email] = useState(userInfo.email)
  const [name] = useState(userInfo.firstName + ' ' + userInfo.lastName)
  const [phone] = useState(userInfo.phone)

  const [show, setShow] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const handleClose = () => setShow(false);
  const handleCloseCancel = () => setShowCancel(false);

  const [confirmedStatus, setConfirmedStatus] = useState(successDeliver)
  const [isAnnualMember] = useState(userInfo.isAnnualMember)

  if (!loading) {
    // Calculate prices
    const addDecimals = (num) => {
      return (Math.round(num * 100) / 100).toFixed(2)
    }
    booking.itemsPrice = addDecimals(
      booking.bookingItems.reduce((acc, item) => acc + item.price * item.qty, 0)
    )

  }

  useEffect(() => {
    dispatch(getBookingDetails(bookingId))
    dispatch(getSettings())
  }, [dispatch, bookingId, successDeliver])

  useEffect(() => {
    const calculateAmount = () => {
      if (booking && settings && settings.exchangeRate && booking.totalPrice) {
        const exchangeRate = settings.exchangeRate;
        setRate(exchangeRate);
        const usdAmount = booking.totalPrice;
        const ghanaianCediAmount = usdAmount * exchangeRate;
        const pesewasAmount = ghanaianCediAmount * 100;
        setAmount(pesewasAmount);
      }
    };

    calculateAmount();
  }, [booking, settings]);

  
  useEffect(() => {
    const confirmBooking = () => {
      dispatch(deliverBooking(booking));
    };
  
    if (isAnnualMember) {
      setConfirmedStatus(true);
      confirmBooking();
    } else {
      setConfirmedStatus(successDeliver);
    }
  }, [isAnnualMember, successDeliver, dispatch, booking]);
  
  
  

  let PaystackProps = {
    email,
    rate: rate ? rate : null, // Convert rate from GHS to pesewas by multiplying by 100
    amount: amount ? parseInt(amount) : null, // Convert amount from pesewas to GHS by dividing by 100
    reference: bookingId,
    metadata: {
      name,
      phone,
      id,
    },
    publicKey,
  }

  // Check if amount and rate are not null
  if (amount && rate) {
    PaystackProps = {
      email,
      rate: rate ? rate : null, // Convert rate from GHS to pesewas by multiplying by 100
      amount: amount ? parseInt(amount) : null, // Convert amount from pesewas to GHS by dividing by 100
      reference: bookingId,
      metadata: {
        name,
        phone,
        id,
      },
      publicKey,
      onSuccess: () => {
        dispatch(deliverBooking(booking))
      }
    }
  }

  const deliverHandler = () => {
    dispatch(deliverBooking(booking))
    setShow(false)
  }

  const cancelHandler = () => {
    dispatch(cancelBooking(booking))
    setShowCancel(false)
  }

  // const [verified, setVerified] = useState(userInfo.isVerified);

  // useEffect(() => {
  //   // Polling interval in milliseconds (10 seconds)
  //   const pollingInterval = 10000;
  //   const interval = setInterval(() => {
  //     // Dispatch an action to fetch the user's updated information
  //     dispatch(getUserDetails(userInfo._id));

  //     // Update the verification status when the user's information is fetched
  //     if (userInfo.isVerified !== verified) {
  //       setVerified(userInfo.isVerified);
  //     }
  //   }, pollingInterval);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [dispatch, userInfo.isVerified, verified]);

  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant='danger'>{error}</Message>
  ) : (
    <>
    {/* Check if user is admin, if so go back to /admin/bookinglist if not then /bookings */}
    {userInfo && userInfo.isAdmin ? (
    <Link to='/admin/bookinglist' className='btn btn-light my-3'>
        Go Back
      </Link>
    ) : (
      <Link to='/bookings' className='btn btn-light my-3'>
        Go Back
      </Link>
    )}

      <h1>Booking #: {booking._id}</h1>
      {userInfo && userInfo.isAdmin && !booking.isDelivered && (
        <>
          <Message variant='info'>
            Check the PayStack account for the payment details. If payment is made
            then click the button below to manually confirm the booking.
          </Message>
          <ListGroup.Item>
            <Button
              type='button'
              className='btn btn-block mark-delivered'
              onClick={() => setShow(true)}
            >
              Mark As Confirmed
            </Button>
          </ListGroup.Item>
          <ListGroup.Item>
            <Button
              type='button'
              className='btn btn-block mark-cancelled'
              onClick={() => setShowCancel(true)}
            >
              Cancel Reservation
            </Button>
          </ListGroup.Item>
        </>
      )}
      
      <Row className='trans-bg'>
        <Col md={12}>
          <ListGroup variant='flush'>
            <ListGroup.Item>
              <h2>Booking Details</h2>
              {(!userInfo.isVerified) ? (
                <Message variant='warning'>
                  Your bookings will not be confirmed until your account has been verfied by our staff. 
                  You should receive an email once your account has been verified. If you have not received an email within 48 hours, 
                  please contact us at <a href="mailto:info@leconciergeclub.com" style={{color:'#000'}}>info@leconciergeclub.com</a>
                  <br/>
                  <br/>
                  Once you've recived the email, logout and log back in again. Thank you for your patience.
                </Message>
              ) : (booking.isDelivered || userInfo.isAnnualMember || confirmedStatus) ? (
                <Message variant='success'>
                  CONFIRMED
                </Message>
              ) : (userInfo.isVerified && !booking.isDelivered && !confirmedStatus) ? (
                <Message variant='warning'>You can pay for your booking below</Message>
              ) : (
                // <Message variant='warning'>Not yet paid for</Message>
                <></>
              )}
      
              
            </ListGroup.Item>

            <ListGroup.Item>
              <Row>
              <Col md={4} >
                  <Image
                    src={booking.bookingItems[0].image}
                    alt={booking.bookingItems[0].name}
                    fluid
                    rounded
                    
                  />
                </Col>
                <Col md={4} >
                  <p>
                    <strong>Service: </strong>{booking.bookingItems[0].name}<br/>
                    {/* Check if item name contains departure, if so then show fields */}
                    {booking.flightDetails.departureDate && booking.flightDetails.arrivalDate ? (
                      <>
                        <strong>Departure Airline: </strong>{booking.flightDetails.departureAirline}<br/>
                        <strong>Departure Flight Number: </strong>{booking.flightDetails.departureFlightNo}<br/>
                        <strong>Departure Date: </strong>{booking.flightDetails.departureDate}<br/>
                        <strong>Departure Time: </strong>{booking.flightDetails.departureTime}<br/>
                        <strong>Arrival Airline: </strong>{booking.flightDetails.arrivalAirline}<br/>
                        <strong>Arrival Flight Number: </strong><br/>{booking.flightDetails.arrivalFlightNo}<br/>
                        <strong>Arrival Date: </strong>{booking.flightDetails.arrivalDate}<br/>
                        <strong>Arrival Time: </strong>{booking.flightDetails.arrivalTime}<br/>
                      </>
                    ) : (
                      <>
                        <strong>Arrival Airline: </strong>{booking.flightDetails.arrivalAirline}<br/>
                        <strong>Arrival Flight Number: </strong>{booking.flightDetails.arrivalFlightNo}<br/>
                        <strong>Arrival Date: </strong>{booking.flightDetails.arrivalDate}<br/>
                        <strong>Arrival Time: </strong>{booking.flightDetails.arrivalTime}<br/>
                      </>
                    )}

                    {/* Check if flightDetails has departureDate only */}
                    {booking.flightDetails.departureDate && !booking.flightDetails.arrivalDate ? (
                      <>
                        <strong>Departure Airline: </strong>{booking.flightDetails.departureAirline}<br/>
                        <strong>Departure Flight Number: </strong>{booking.flightDetails.departureFlightNo}<br/>
                        <strong>Departure Date: </strong>{booking.flightDetails.departureDate}<br/>
                        <strong>Departure Time: </strong>{booking.flightDetails.departureTime}
                      </>
                    ) : (
                      <>
                      </>
                    )}

                    {/* Check if flightDetails has arrivalDate only */}
                    {!booking.flightDetails.departureDate && booking.flightDetails.arrivalDate ? (
                      <>
                        <strong>Arrival Airline: </strong>{booking.flightDetails.arrivalAirline}<br/>
                        <strong>Arrival Flight Number: </strong>{booking.flightDetails.arrivalFlightNo}<br/>
                        <strong>Arrival Date: </strong>{booking.flightDetails.arrivalDate}<br/>
                        <strong>Arrival Time: </strong>{booking.flightDetails.arrivalTime}
                      </>
                    ) : (
                      <>
                      </>
                    )}
                  </p>
                </Col>
                <Col md={4}>

                    <ListGroup.Item>
                    <p>
                    Adults: {booking.bookingItems[0].qtyAdult} (${booking.bookingItems[0].priceAdult} each)<br/>
                    Young Adults: {booking.bookingItems[0].qtyYoungAdult} (${booking.bookingItems[0].priceYoungAdult} each)<br/>
                    Children: {booking.bookingItems[0].qtyChild} (${booking.bookingItems[0].priceChild} each)<br/>
                    Infants: {booking.bookingItems[0].qtyInfant} (${booking.bookingItems[0].priceInfant} each)
                    </p>
                  </ListGroup.Item>
                  
                    <p>        
                      {settings && settings.exchangeRate ? (
                        <>
                          <strong>Exchange Rate: </strong>{settings.exchangeRate}
                          {/* Show the total in GHS by using the settings.exchangeRate */}
                          <br/>
                          <strong>Total GHS: </strong>{(booking.totalPrice * settings.exchangeRate).toFixed(2)}<br/>
                          Total USD: ${booking.totalPrice.toFixed(2)}
                          <br/>
                          <br/>
                          VAT is included in the final price<br/>
                        </>
                      ) : (
                        <>
                          Exchange Rate: N/A
                          </>
                      )}
                    </p>
                    {/* {!userInfo.isAnnualMember && ( */}
                      {(booking.isDelivered || confirmedStatus) ? (
                        <>
                          <ListGroup.Item>
                            <Button

                              type='button'
                              className='btn btn-block mark-cancelled'
                              onClick={setShowCancel}
                            >
                              Cancel Reservation
                            </Button>
                          </ListGroup.Item>
                        </>
                      ) : (!userInfo.isVerified) ? (
                        <>
                          <ListGroup.Item>
                            <Message variant='warning'>
                              You will be able to pay once your account has been verified by our staff, thank you.
                            </Message>
                          </ListGroup.Item>
                        </>
                      ) : (
                        <>
                          <ListGroup.Item>
                            <>
                              <PaystackButton 
                                className="paystack-button btn btn-block"
                                onClose={() => console.log('closed')}
                                text="Pay Now (GHS)"
                                currency='GHS'
                                {...PaystackProps}
                              />
                            </>
                          </ListGroup.Item>
                        </>
                      )}
                
              
                </Col>
                
              </Row>
              
            </ListGroup.Item>

            {/* Loop through the array of objects in booking.passengers and show the information */}
            <hr />
            <h1>Passenger Information</h1>
            <Row>
            {booking.passengers.map((passenger, index) => (
              <Col md={4} key={index}>
                <ListGroup.Item key={index}>
                  <h2 style={{color: '#000'}}>Passenger: {index + 1}</h2>
                  <p><strong>Name: </strong>{passenger.name}<br/>
                  <strong>Date of Birth: </strong>{passenger.dateOfBirth}<br/>
                  <strong>Nationality: </strong>{passenger.nationality}<br/>
                  <strong>Passport Number: </strong>{passenger.passportNumber}</p>
                
                </ListGroup.Item>
              </Col>
            ))}
            </Row>
           
          </ListGroup>
        </Col>
      </Row>

      {/* Modal for confirm */}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body className='dark center text-center'>
          Are you sure you want to manually confirm the payment?
        </Modal.Body>
        <Modal.Footer className='center'>
          <Button variant="primary" onClick={deliverHandler}>
            CONFIRM
          </Button>
          <Button variant="primary" onClick={handleClose}>
            CANCEL
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal to unverify */}
      <Modal show={showCancel} onHide={handleCloseCancel} centered>
        <Modal.Body className='dark center text-center'>
          Are you sure you want to cancel the booking?
        </Modal.Body>
        <Modal.Footer className='center'>
          <Button variant="primary" onClick={cancelHandler}>
            CONFIRM
          </Button>
          <Button variant="primary" onClick={handleCloseCancel}>
            CANCEL
          </Button>
        </Modal.Footer>
      </Modal>
      
    </>
  )
}

export default BookingScreen
