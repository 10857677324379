import React, { useState, useEffect } from 'react'
import { Row, Col, ListGroup, Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import FormContainer from '../components/FormContainer'
import { savePassengerDetails } from '../actions/cartActions'
// import { set } from 'mongoose'

const PassengerScreen = ({ history }) => {
  
  const dispatch = useDispatch()
  
  const cart = useSelector((state) => state.cart)
  const { cartItems } = cart

  const [message, setMessage] = useState(''); 
  const [noErrors, setNoErrors] = useState(false)
  const [passengers, setPassengers] = useState([]);

  // Adults Form
  const [messageAdult, setMessageAdult] = useState('');
  const [name, setName] = useState(null)
  const [age, setAge] = useState(null)
  const [nationality, setNationality] = useState(null)
  const [passportNumber, setPassportNumber] = useState(null)
  const [AdultFormDisabled, setAdultFormDisabled] = useState(false)
  const [adultsFilled, setAdultsFilled] = useState(false);
  // Adults Values
  const [values, setValues] = useState({
    name: "",
    dateOfBirth: "",
    nationality: "",
    passportNumber: "",
    ageGroup: "adult"
  });
  // Adults onChange
  const handleChange = (event) => {
    const value = event.target.value;
    setValues({
      ...values,
      [event.target.name]: value,
      ageGroup: "adult"
    });
  };

  // Young Adults Form
  const [messageYoungAdult, setMessageYoungAdult] = useState('');
  const [nameYoungAdult, setNameYoungAdult] = useState(null)
  const [ageYoungAdult, setAgeYoungAdult] = useState(null)
  const [nationalityYoungAdult, setNationalityYoungAdult] = useState(null)
  const [passportNumberYoungAdult, setPassportNumberYoungAdult] = useState(null)
  const [YoungAdultFormDisabled, setYoungAdultFormDisabled] = useState(false)
  const [youngAdultsFilled, setYoungAdultsFilled] = useState(false);
  // Young Adults Values
  const [valuesYoungAdult, setValuesYoungAdult] = useState({
    name: "",
    dateOfBirth: "",
    nationality: "",
    passportNumber: "",
    ageGroup: "youngAdult"
  });
  // Young Adults onChange
  const handleChangeYoungAdult = (event) => {
    const value = event.target.value;
    setValuesYoungAdult({
      ...valuesYoungAdult,
      [event.target.name]: value,
      ageGroup: "youngAdult"
    });
  };


  // Children Form
  const [messageChild, setMessageChild] = useState('');
  const [nameChild, setNameChild] = useState(null)
  const [ageChild, setAgeChild] = useState(null)
  const [nationalityChild, setNationalityChild] = useState(null)
  const [passportNumberChild, setPassportNumberChild] = useState(null)
  const [ChildFormDisabled, setChildFormDisabled] = useState(false)
  const [childsFilled, setChildsFilled] = useState(false);
  // Children Values
  const [valuesChild, setValuesChild] = useState({
    name: "",
    dateOfBirth: "",
    nationality: "",
    passportNumber: "",
    ageGroup: "child"
  });
  // Children onChange
  const handleChangeChild = (event) => {
    const value = event.target.value;
    setValuesChild({
      ...valuesChild,
      [event.target.name]: value,
      ageGroup: "child"
    });
  };

  // Infants Form
  const [messageInfant, setMessageInfant] = useState('');
  const [nameInfant, setNameInfant] = useState(null)
  const [ageInfant, setAgeInfant] = useState(null)
  const [nationalityInfant, setNationalityInfant] = useState(null)
  const [passportNumberInfant, setPassportNumberInfant] = useState(null)
  const [InfantFormDisabled, setInfantFormDisabled] = useState(false)
  const [InfantsFilled, setInfantsFilled] = useState(false);
  // Infants Values
  const [valuesInfant, setValuesInfant] = useState({
    name: "",
    dateOfBirth: "",
    nationality: "",
    passportNumber: "",
    ageGroup: "infant"
  });
  // Infants onChange
  const handleChangeInfant = (event) => {
    const value = event.target.value;
    setValuesInfant({
      ...valuesInfant,
      [event.target.name]: value,
      ageGroup: "infant"
    });
  };
  
  // const userLogin = useSelector((state) => state.userLogin)
  // const { userInfo } = userLogin

  const addPassenger = (values) => {
    const newPassengers = [...passengers, values];
    setPassengers(newPassengers);
  };

  // Check the age of the users and verify the group theyre in
  const verifyAge = (ageGroup) => {
    // console.log('Fired age verification')
    // e.preventDefault()
    const today = new Date()
    let birthDate
    if (ageGroup === "adult") {
      birthDate = new Date(values.dateOfBirth)
    } else if (ageGroup === "youngAdult") {
      birthDate = new Date(valuesYoungAdult.dateOfBirth)
    } else if (ageGroup === "child") {
      birthDate = new Date(valuesChild.dateOfBirth)
    } else if (ageGroup === "infant") {
      birthDate = new Date(valuesInfant.dateOfBirth)
    }
    let passengerAge = today.getFullYear() - birthDate.getFullYear()
    let m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      passengerAge--
    }

    switch (ageGroup) {
      case "adult":
        // console.log('Fired adult age verification')
        if (passengerAge < 18) {
          return false
        } else {
          return true
        }
      case "youngAdult":
        if (passengerAge < 12) {
          return false
        } else if (passengerAge > 17) {
          return false
        } else {
          return true
        }
      case "child":
        if (passengerAge < 6) {
          return false
        } else if (passengerAge > 11) {
          return false
        } else {
          return true
        }
      case "infant":
        if (passengerAge > 5) {
          return false
        } else {
          return true
        }
      default:
        return false
    }
  }

  // Validate the form, add parameter for ageGroup
  const validate = (ageGroup) => {
    // e.preventDefault()
    switch (ageGroup) {
      case "adult":
        console.log('Fired adult validation')
        // Check if the name is empty
        if (values.name === "") {
          setMessageAdult('Please enter the full name of the adult passenger')
          setNoErrors(false)
          return false
        }
        // Check if the date of birth is empty
        if (values.dateOfBirth === "") {
          setMessageAdult('Please enter the date of birth of the adult passenger')
          setNoErrors(false)
          return false
        }
        // Verify users are 18 years old
        if (verifyAge("adult") === false) {
          setMessageAdult('The adult must be 18 years or older')
          setNoErrors(false)
          return false
        }
        // Check if the nationality is empty
        if (values.nationality === "") {
          setMessage('Please enter the nationality')
          setNoErrors(false)
          return false
        }
        // Check if the passport number is empty or less than 8 characters
        if (values.passportNumber === "") {
          setMessageAdult('Please enter the passport number of the adult passenger')
          setNoErrors(false)
          return false
        }
        if (values.passportNumber.length < 8) {
          setMessageAdult('The passport number must be 8 characters or more')
          setNoErrors(false)
          return false
        }
        setMessageAdult('')
        setNoErrors(true)
        setName(null);
        setAge(null);
        setNationality(null);
        setPassportNumber(null);
        return true
      case "youngAdult":
        // console.log('Fired young adult validation')
        // Check if the name is empty
        if (valuesYoungAdult.name === "") {
          setMessageYoungAdult('Please enter the full name of the young adult passenger')
          setNoErrors(false)
          return false
        }
        // Check if the date of birth is empty
        if (valuesYoungAdult.dateOfBirth === "") {
          setMessageYoungAdult('Please enter the date of birth of the young adult passenger')
          setNoErrors(false)
          return false
        }
        // Verify users are less than 18 years old
        if (verifyAge("youngAdult") === false) {
          setMessageYoungAdult('The young adult must be between 12 and 18 years old')
          setNoErrors(false)
          return false
        }
        // Check if the nationality is empty
        if (valuesYoungAdult.nationality === "") {
          setMessageYoungAdult('Please enter the nationality')
          setNoErrors(false)
          return false
        }
        // Check if the passport number is empty or less than 8 characters
        if (valuesYoungAdult.passportNumber === "") {
          setMessageYoungAdult('Please enter the passport number of the young adult passenger')
          setNoErrors(false)
          return false
        }
        if (valuesYoungAdult.passportNumber.length < 8) {
          setMessageYoungAdult('The passport number must be 8 characters or more')
          setNoErrors(false)
          return false
        }
        setMessageYoungAdult('')
        setNoErrors(true)
        setNameYoungAdult(null);
        setAgeYoungAdult(null);
        setNationalityYoungAdult(null);
        setPassportNumberYoungAdult(null);
        return true
      case "child":
        // console.log('Fired child validation')
        // Check if the name is empty
        if (valuesChild.name === "") {
          setMessageChild('Please enter the full name of the child passenger')
          setNoErrors(false)
          return false
        }
        // Check if the date of birth is empty
        if (valuesChild.dateOfBirth === "") {
          setMessageChild('Please enter the date of birth of the passenger')
          setNoErrors(false)
          return false
        }
        // Verify users are less than 18 years old
        if (verifyAge("child") === false) {
          setMessageChild('The child must be between 5 and 12 years old')
          setNoErrors(false)
          return false
        } 
        // Check if the nationality is empty
        if (valuesChild.nationality === "") {
          setMessageChild('Please enter the nationality')
          setNoErrors(false)
          return false
        }
        // Check if the passport number is empty or less than 8 characters
        if (valuesChild.passportNumber === "") {
          setMessageChild('Please enter the passport number of the passenger')
          setNoErrors(false)
          return false
        }
        if (valuesChild.passportNumber.length < 8) {
          setMessageChild('The passport number must be 8 characters or more')
          setNoErrors(false)
          return false
        }
        setMessageChild('')
        setNoErrors(true)
        setNameChild(null);
        setAgeChild(null);
        setNationalityChild(null);
        setPassportNumberChild(null);
        return true
      case "infant":
        // console.log('Fired infant validation')
        // Check if the name is empty
        if (valuesInfant.name === "") {
          setMessageInfant('Please enter the full name of the passenger')
          setNoErrors(false)
          return false
        }
        // Check if the date of birth is empty
        if (valuesInfant.dateOfBirth === "") {
          setMessageInfant('Please enter the date of birth of the passenger')
          setNoErrors(false)
          return false
        }
        // Verify users are 5 and under
        if (verifyAge("infant") === false) {
          setMessageInfant('The passenger must be 5 or under')
          setNoErrors(false)
          return false
        }
        // Check if the nationality is empty
        if (valuesInfant.nationality === "") {
          setMessageInfant('Please enter the nationality')
          setNoErrors(false)
          return false
        }
        // Check if the passport number is empty or less than 8 characters
        if (valuesInfant.passportNumber === "") {
          setMessageInfant('Please enter the passport number of the passenger')
          setNoErrors(false)
          return false
        }
        if (valuesInfant.passportNumber.length < 8) {
          setMessageInfant('The passport number must be 8 characters or more')
          setNoErrors(false)
          return false
        }
        setMessageInfant('')
        setNoErrors(true)
        setNameInfant(null);
        setAgeInfant(null);
        setNationalityInfant(null);
        setPassportNumberInfant(null);
        return true
      default:
        return false
      }
  }

  // Submit
  const handleSubmitAdult = (event) => {
    event.preventDefault();
    // Validate the submission
    if (validate("adult") === false) {
      // console.log('Fired adult validation: Not valid')
      return
    } else {
      // console.log('Fired adult validation: Valid')
      addPassenger(values);
      setValues({});
      dispatch(savePassengerDetails(passengers))
    }
  }

  const handleSubmitYoungAdult = (event) => {
    event.preventDefault();
    // console.log('Fired young adult submit')
    // Validate the submission
    if (validate("youngAdult") === false) {
      // console.log('Fired young adult validation: not valid')
      return
    } else {
      // console.log('Fired young adult validation: valid')
      addPassenger(valuesYoungAdult);
      setValuesYoungAdult({});
      dispatch(savePassengerDetails(passengers))
    }
  }

  const handleSubmitChild = (event) => {
    event.preventDefault();
    // console.log('Fired child submit')
    // Validate the submission
    if (validate("child") === false) {
      // console.log('Fired child validation: not valid')
      return
    } else {
      // console.log('Fired child validation: valid')
      addPassenger(valuesChild);
      setValuesChild({});
      dispatch(savePassengerDetails(passengers))
    }
  }

  const handleSubmitInfant = (event) => {
    event.preventDefault();
    // console.log('Fired infant submit')
    // Validate the submission
    if (validate("infant") === false) {
      // console.log('Fired infant validation: not valid')
      return
    } else {
      // console.log('Fired infant validation: valid')
      addPassenger(valuesInfant);
      setValuesInfant({});
      dispatch(savePassengerDetails(passengers))
    }
  }

  const nextPageHandler = (e) => {
    e.preventDefault()
    // Check if the amount of passengers is equal to the amount of passengers in the cart
    if (passengers.length !== cartItems[0].qty) {
      setMessage('Please enter the correct amount of passengers')
      return
    } 
    // Check if there are any errors
    if (noErrors === false) {
      setMessage('Please correct the errors before continuing')
      return
    }
    // Move to the next page
    history.push('/place-booking')
  }

  // Run this code when the component mounts
  useEffect(() => {
    // dispatch(savePassengerDetails(passengers))

    // Check if the amount of adult passengers is equal to the amount of adults in the cart by comparing the length 
    // of the passengers arrays that have an ageGroup of "adult" to the qtyAdult in the cart
    // console.log("Adult Passengers", passengers.filter(passenger => passenger.ageGroup === "adult").length)
    if (passengers.filter(passenger => passenger.ageGroup === "adult").length === cartItems[0].qtyAdult) {
      // Disable the adult form and remove the message to add more passengers
      setAdultFormDisabled(true)
      setAdultsFilled('')
    } else if (passengers.filter(passenger => passenger.ageGroup === "adult").length === 0) {
      // Enable the adult form and message to add more passengers
      setAdultFormDisabled(false)
      setAdultsFilled('')
    } else {
      // Enable the adult form and message to add more passengers
      setAdultFormDisabled(false)
      setAdultsFilled('Passenger saved. Please add the remaining passengers')
    }

    // Check if the amount of young adult passengers is equal to the amount of young adults in the cart by comparing the length
    // of the passengers arrays that have an ageGroup of "youngAdult" to the qtyYoungAdult in the cart
    // console.log("Young Adult Passengers", passengers.filter(passenger => passenger.ageGroup === "youngAdult").length)
    if (passengers.filter(passenger => passenger.ageGroup === "youngAdult").length === cartItems[0].qtyYoungAdult) {
      // Disable the young adult form and remove the message to add more passengers
      setYoungAdultFormDisabled(true)
      setYoungAdultsFilled('')
    } else if (passengers.filter(passenger => passenger.ageGroup === "youngAdult").length === 0) {
      // Enable the young adult form and message to add more passengers
      setYoungAdultFormDisabled(false)
      setYoungAdultsFilled('')
    } else {
      // Enable the young adult form and message to add more passengers
      setYoungAdultFormDisabled(false)
      setYoungAdultsFilled('Passenger saved. Please add the remaining passengers')
    }

    // Check if the amount of child passengers is equal to the amount of childs in the cart by comparing the length 
    // of the passengers arrays that have an ageGroup of "child" to the qtyChild in the cart
    // console.log("Child Passengers", passengers.filter(passenger => passenger.ageGroup === "child").length)
    if (passengers.filter(passenger => passenger.ageGroup === "child").length === cartItems[0].qtyChild) {
      // Disable the child form and remove the message to add more passengers
      setChildFormDisabled(true)
      setChildsFilled('')
    } else if (passengers.filter(passenger => passenger.ageGroup === "child").length === 0) {
      // Enable the child form and message to add more passengers
      setChildFormDisabled(false)
      setChildsFilled('')
    } else {
      // Enable the child form and message to add more passengers
      setChildFormDisabled(false)
      setChildsFilled('Passenger saved. Please add the remaining passengers')
    }

    // Check if the amount of infant passengers is equal to the amount of infants in the cart by comparing the length 
    // of the passengers arrays that have an ageGroup of "infant" to the qtyInfant in the cart
    // console.log("Infant Passengers", passengers.filter(passenger => passenger.ageGroup === "infant").length)
    if (passengers.filter(passenger => passenger.ageGroup === "infant").length === cartItems[0].qtyInfant) {
      // Disable the infant form and remove the message to add more passengers
      setInfantFormDisabled(true)
      setInfantsFilled('')
    } else if (passengers.filter(passenger => passenger.ageGroup === "infant").length === 0) {
      // Enable the infant form and message to add more passengers
      setInfantFormDisabled(false)
      setInfantsFilled('')
    } else {
      // Enable the adult form and message to add more passengers
      setInfantFormDisabled(false)
      setInfantsFilled('Passenger saved. Please add the remaining passengers')
    }

    dispatch(savePassengerDetails(passengers))

  }, [passengers, cartItems, dispatch])

  return (  
    <>
    {/* <CheckoutSteps step1 /> */}
    <Row className='trans-bg'>
      <Col xl={8} lg={8} md={8} sm={12}>
        <FormContainer>
          <h1>Passenger Details</h1>
          <p>Please enter the information for each passenger below. Once you are done click "Add Passenger" to add the passenger to the list.
          After all the passengers have been entered, continue to the next page to confirm the details of your booking.</p>
          <hr />
          {/* {passengerDetails()} */}

          {/* Map through the amount of passengers and sort by the qtyAdult, qtyChild, and qtyInfant in cart items */}
          {message && <Message variant='danger'>{message}</Message>}

          {/* Check if there is an qtyAdult in the cart */}
          {cartItems[0].qtyAdult > 0 &&
            <>
              {/* Check if the adult form is disabled */}
              {AdultFormDisabled === true &&
                <>
                  <h3 className='dark'>Adult</h3>
                  <Message variant='success'>All adult passengers have been added</Message>
                </>
              }
              {/* Check if the adult form is enabled */}
              {AdultFormDisabled === false && 
              <Form className="mb-50 mt-50" onSubmit={handleSubmitAdult}>
                <h3 className='dark'>Adult</h3>
                <p>18 years and older</p>
                <br/>
                {messageAdult && <Message variant='danger'>{messageAdult}</Message>}
                {adultsFilled && <Message variant='warning'>{adultsFilled}</Message>}
                <Form.Group controlId='adult'>
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    type='text'
                    name='name'
                    placeholder='Enter the full name of the passenger'
                    value={name}
                    onChange={handleChange}
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId='age'>
                  <Form.Label>Date of Birth</Form.Label>
                  <Form.Control
                    type='date'
                    name='dateOfBirth'
                    placeholder='Select date of birth'
                    value={age}
                    onChange={handleChange}
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId='nationality'>
                  <Form.Label>Nationality</Form.Label>
                  <Form.Control
                    name='nationality'
                    type='text'
                    placeholder='Enter the passengers nationality'
                    value={nationality}
                    onChange={handleChange}
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId='passportNumber' className='mb-50'>
                  <Form.Label>Passport Number</Form.Label>
                  <Form.Control
                    name='passportNumber'
                    type='text'
                    placeholder='Enter passport number'
                    value={passportNumber}
                    onChange={handleChange}
                  ></Form.Control>
                </Form.Group>
                <Button type='submit' variant='primary'>
                  Add Passenger
                </Button>
              </Form>
              }
            </>
          }

          {/* Check if there an qtyYoungAdult in the cart */}
          {cartItems[0].qtyYoungAdult > 0 &&
            <>
              {/* Check if the young adult form is disabled */}
              {YoungAdultFormDisabled === true &&
                <>
                  <h3 className='dark'>Young Adult</h3>
                  <Message variant='success'>All young adult passengers have been added</Message>
                </>
              }
              {/* Check if the young adult form is enabled */}
              {YoungAdultFormDisabled === false &&
                <Form className="mb-50 mt-50" onSubmit={handleSubmitYoungAdult}>
                  <h3 className='dark'>Young Adult</h3>
                  <p>12 to 17 years old</p>
                  <br/>
                  {messageYoungAdult && <Message variant='danger'>{messageYoungAdult}</Message>}
                  {youngAdultsFilled && <Message variant='warning'>{youngAdultsFilled}</Message>}
                  <Form.Group controlId='youngAdult'>
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                      type='text'
                      name='name'
                      placeholder='Enter the full name of the passenger'
                      value={nameYoungAdult}
                      onChange={handleChangeYoungAdult}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group controlId='age'>
                    <Form.Label>Date of Birth</Form.Label>
                    <Form.Control
                      type='date'
                      name='dateOfBirth'
                      placeholder='Select date of birth'
                      value={ageYoungAdult}
                      onChange={handleChangeYoungAdult}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId='nationality'>
                  <Form.Label>Nationality</Form.Label>
                  <Form.Control
                    name='nationality'
                    type='text'
                    placeholder='Enter the passengers nationality'
                    value={nationalityYoungAdult}
                    onChange={handleChangeYoungAdult}
                  ></Form.Control>
                </Form.Group>

                  <Form.Group controlId='passportNumber' className='mb-50'>
                    <Form.Label>Passport Number</Form.Label>
                    <Form.Control
                      name='passportNumber'
                      type='text'
                      placeholder='Enter passport number'
                      value={passportNumberYoungAdult}
                      onChange={handleChangeYoungAdult}
                    ></Form.Control>
                  </Form.Group>
                  <Button type='submit' variant='primary'>
                    Add Passenger
                  </Button>
                </Form>
              }
            </>
          }

          {/* Check if there is an qtyChild in the cart */}
          {cartItems[0].qtyChild > 0 &&
            <>
              {/* Check if the child form is disabled */}
              {ChildFormDisabled === true &&
                <>
                  <h3 className='dark'>Child</h3>
                  <Message variant='success'>All child passengers have been added</Message>
                </>
              }
              {/* Check if the child form is enabled */}
              {ChildFormDisabled === false &&
                <Form className="mb-50 mt-50" onSubmit={handleSubmitChild}>
                  <h3 className='dark'>Child</h3>
                  <p>6 to 11 years old</p>
                  <br/>
                  {messageChild && <Message variant='danger'>{messageChild}</Message>}
                  {childsFilled && <Message variant='warning'>{childsFilled}</Message>}
                  <Form.Group controlId='child'>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type='text'
                      name='name'
                      placeholder='Enter the full name of the passenger'
                      value={nameChild}
                      onChange={handleChangeChild}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group controlId='age'>
                    <Form.Label>Date of Birth</Form.Label>
                    <Form.Control
                      type='date'
                      name='dateOfBirth'
                      placeholder='Select date of birth'
                      value={ageChild}
                      onChange={handleChangeChild}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId='nationality'>
                  <Form.Label>Nationality</Form.Label>
                  <Form.Control
                    name='nationality'
                    type='text'
                    placeholder='Enter the passengers nationality'
                    value={nationalityChild}
                    onChange={handleChangeChild}
                  ></Form.Control>
                </Form.Group>

                  <Form.Group controlId='passportNumber' className='mb-50'>
                    <Form.Label>Passport Number</Form.Label>
                    <Form.Control
                      name='passportNumber'
                      type='text'
                      placeholder='Enter passport number'
                      value={passportNumberChild}
                      onChange={handleChangeChild}
                    ></Form.Control>
                  </Form.Group>
                  <Button type='submit' variant='primary'>
                    Add Passenger
                  </Button>
                </Form>
              }
            </>
          }

          {/* Check if there is an qtyInfant in the cart */}
          {cartItems[0].qtyInfant > 0 &&
            <>
              {/* Check if the infant form is disabled */}
              {InfantFormDisabled === true &&
                <>
                  <h3 className='dark'>Infant</h3>
                  <Message variant='success'>All children 5 and under have been added</Message>
                </>
              }
              {/* Check if the infant form is enabled */}
              {InfantFormDisabled === false &&
                <Form className="mb-50 mt-50" onSubmit={handleSubmitInfant}>
                  <h3 className='dark'>Infant</h3>
                  <p>Children 5 and under are complimentary</p>
                  <br/>
                  {messageInfant && <Message variant='danger'>{messageInfant}</Message>}
                  {InfantsFilled && <Message variant='warning'>Passenger saved. Please add the remaining passengers.</Message>}
                  <Form.Group controlId='infant'>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type='text'
                      name='name'
                      placeholder='Enter the full name of the passenger'
                      value={nameInfant}
                      onChange={handleChangeInfant}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group controlId='age'>
                    <Form.Label>Date of Birth</Form.Label>
                    <Form.Control
                      type='date'
                      name='dateOfBirth'
                      placeholder='Select date of birth'
                      value={ageInfant}
                      onChange={handleChangeInfant}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId='nationality'>
                    <Form.Label>Nationality</Form.Label>
                    <Form.Control
                      name='nationality'
                      type='text'
                      placeholder='Enter the passengers nationality'
                      value={nationalityInfant}
                      onChange={handleChangeInfant}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId='passportNumber' className='mb-50'>
                    <Form.Label>Passport Number</Form.Label>
                    <Form.Control
                      name='passportNumber'
                      type='text'
                      placeholder='Enter passport number'
                      value={passportNumberInfant}
                      onChange={handleChangeInfant}
                    ></Form.Control>
                  </Form.Group>
                  <Button type='submit' variant='primary'>
                    Add Passenger
                  </Button>
                </Form>
              }
            </>
          }

          <hr/>
 
        </FormContainer>
  
        
      </Col>
      <Col xl={4} lg={4} md={4} sm={12}>
      <h1>Passenger(s)</h1>
        {/* Check if passenger is not empty */}

          <ListGroup variant='flush'>
            {passengers.map((passenger, index) => (
              <>
                <ListGroup.Item key={index}>
                  <h5>Name: {passenger.name}</h5>
                  <h5>Date of Birth: {passenger.dateOfBirth}</h5>
                  <h5>Nationality: {passenger.nationality}</h5>
                  <h5>Passport #: {passenger.passportNumber}</h5>
                </ListGroup.Item>
                <br/>
              </>
            ))}
          </ListGroup>
          <ListGroup.Item>
              <Button
                type='button'
                className='btn-block'
                disabled={cartItems.length === 0}
                onClick={nextPageHandler}
              >
                Proceed To Confirmation
              </Button>
            </ListGroup.Item>
         
      </Col>
    </Row>
    </>
  )
}

export default PassengerScreen
