import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import Meta from '../components/Meta'

const ServicesScreen = ({ match }) => {

  const [posts, setPosts] = useState(null);

  useEffect(() => {
    async function loadPosts() {
      const response = await fetch('https://leconcierge.dreamhosters.com/wp-json/wp/v2/pages/97');
      if(!response.ok) {
        // oups! something went wrong
        return;
      }
        
      const posts = await response.json();
      // console.log("Services:", posts)
      setPosts(posts);
    }
    
    loadPosts();
  }, [])

  if (posts === null) return null

  return (
    <>
      <Meta />
     
      <h1><div dangerouslySetInnerHTML={{__html: posts.acf.page_header}} /></h1>
      {/* <p className="mb-50">
        <div dangerouslySetInnerHTML={{__html: posts.acf.page_intro}} />
      </p> */}
      {/* <h3><div dangerouslySetInnerHTML={{__html: posts.acf.second_header}} /></h3> */}
      {/* <div dangerouslySetInnerHTML={{__html: posts.acf.second_text_area}} /> */}
          
          <Row>
            <Col sm={12} md={6} lg={6} xl={6}  className="mb-50">
              <img src={posts.acf.first_service_image} alt='Airport VIP Service' width="100%" className="mb-10"/>
            </Col>
            <Col sm={12} md={6} lg={6} xl={6}  className="mb-50">
              <h5><div dangerouslySetInnerHTML={{__html: posts.acf.first_service_header}} /></h5>
              <div dangerouslySetInnerHTML={{__html: posts.acf.first_service_text_area}} />
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} lg={6} xl={6}  className="mb-50">
              <img src={posts.acf.second_service_image} alt='Private Jet Charter' width="100%" className="mb-10"/>
            </Col>
            <Col sm={12} md={6} lg={6} xl={6}  className="mb-50">
              <h5><div dangerouslySetInnerHTML={{__html: posts.acf.second_service_header}} /></h5>
              <div dangerouslySetInnerHTML={{__html: posts.acf.second_service_text_area}} />
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} lg={6} xl={6}  className="mb-50">
              <img src={posts.acf.third_service_image} alt='Transportation and Security' width="100%" className="mb-10"/>
            </Col>
            <Col sm={12} md={6} lg={6} xl={6}  className="mb-50">
              <h5><div dangerouslySetInnerHTML={{__html: posts.acf.third_service_header}} /></h5>
              <div dangerouslySetInnerHTML={{__html: posts.acf.third_service_text_area}} />
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} lg={6} xl={6}  className="mb-50">
              <img src={posts.acf.fourth_service_image} alt='Luxury Accommodation' width="100%" className="mb-10"/>
            </Col>
            <Col sm={12} md={6} lg={6} xl={6}  className="mb-50">
              <h5><div dangerouslySetInnerHTML={{__html: posts.acf.fourth_service_header}} /></h5>
              <div dangerouslySetInnerHTML={{__html: posts.acf.fourth_service_text_area}} />
            </Col>
          </Row>

          <Row>
            <Col sm={12} md={12} lg={12} xl={12}>
              <h2><div dangerouslySetInnerHTML={{__html: posts.acf.fourth_area_left_column_title}} /></h2>
              <div dangerouslySetInnerHTML={{__html: posts.acf.fourth_area_left_column_text}} />  
            </Col>
          </Row>

        </>
    
  )
}

export default ServicesScreen
