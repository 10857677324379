import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import {
  exchangeRateReducer,
  welcomeMessageReducer,
  confirmationEmailReducer,
  cancellationEmailReducer,
} from './reducers/settingsReducers'

import {
  productListReducer,
  productDetailsReducer,
  productDeleteReducer,
  productCreateReducer,
  productUpdateReducer,
  productReviewCreateReducer,
  productTopRatedReducer,
} from './reducers/productReducers'
import { cartReducer } from './reducers/cartReducers'
import {
  userLoginReducer,
  userRegisterReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  userListReducer,
  userDeleteReducer,
  userUpdateReducer,
  userVerifyReducer,
  userMessageReducer,
  userPasswordLinkReducer,
  userForgotPasswordReducer
} from './reducers/userReducers'
import {
  bookingCreateReducer,
  bookingDetailsReducer,
  bookingPayReducer,
  bookingDeliverReducer,
  bookingCancelReducer,
  bookingListMyReducer,
  bookingListReducer,
} from './reducers/bookingReducers'

const reducer = combineReducers({
  productList: productListReducer,
  productDetails: productDetailsReducer,
  productDelete: productDeleteReducer,
  productCreate: productCreateReducer,
  productUpdate: productUpdateReducer,
  productReviewCreate: productReviewCreateReducer,
  productTopRated: productTopRatedReducer,
  cart: cartReducer,
  userVerify: userVerifyReducer,
  userMessage: userMessageReducer,
  userForgotPassword: userForgotPasswordReducer,
  userPasswordLink: userPasswordLinkReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userUpdate: userUpdateReducer,
  bookingCreate: bookingCreateReducer,
  bookingDetails: bookingDetailsReducer,
  bookingPay: bookingPayReducer,
  bookingDeliver: bookingDeliverReducer,
  bookingCancel: bookingCancelReducer,
  bookingListMy: bookingListMyReducer,
  bookingList: bookingListReducer,
  exchangeRate: exchangeRateReducer,
  welcomeMessage: welcomeMessageReducer,
  confirmationEmail: confirmationEmailReducer,
  cancellationEmail: cancellationEmailReducer
})

const cartItemsFromStorage = localStorage.getItem('cartItems')
  ? JSON.parse(localStorage.getItem('cartItems'))
  : []

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null

const flightDetailsFromStorage = localStorage.getItem('flightDetails')
  ? JSON.parse(localStorage.getItem('flightDetails'))
  : {}

const initialState = {
  cart: {
    cartItems: cartItemsFromStorage,
    flightDetails: flightDetailsFromStorage,
  },
  userLogin: { userInfo: userInfoFromStorage },
}

const middleware = [thunk];

let store;

if (process.env.NODE_ENV === 'production') {
  store = createStore(
    reducer,
    initialState,
    applyMiddleware(...middleware)
  );
} else {
  store = createStore(
    reducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
  );
}

export default store
