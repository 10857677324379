import React, { useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button, Dropdown } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { listBookings } from '../actions/bookingActions'

const BookingListScreen = ({ history }) => {

  const dispatch = useDispatch()

  const bookingList = useSelector((state) => state.bookingList)
  const { loading, error, bookings } = bookingList

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listBookings())
    } else {
      history.push('/login')
    }

  }, [dispatch, history, userInfo])


  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone: 'Africa/Accra' // Set the desired timezone (Ghana)
  };

  const formatter = new Intl.DateTimeFormat('en-GH', options);

  return (
    <>
      <h3>BOOKINGS: ALL</h3>
      <Dropdown>
        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
          Filter by
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item href="/admin/bookinglist">All</Dropdown.Item>
          <Dropdown.Item href="/admin/bookinglist/arrivals">Arrivals</Dropdown.Item>
          <Dropdown.Item href="/admin/bookinglist/departures">Departures</Dropdown.Item>
          <Dropdown.Item href="/admin/bookinglist/arrivals-and-departures">Arrival & Departures</Dropdown.Item>
          <Dropdown.Item href="/admin/bookinglist/transit">Transit</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <br/>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
        { bookings.sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
          }).map((booking, i) => (
          <Table striped bordered hover responsive className='table-sm table-white'>
            <thead>
            <tr>
              <th>SERVICE</th>
              <th>BOOKING CREATED ON</th>
              <th>Flight Details</th>
              <th>Flight Time</th>
              <th>Name</th>
              <th>Nationality</th>
              <th>Passport #</th>
              <th>DOB</th>
              <th>TOTAL</th>
              <th>CONFIRMED</th>
              <th>DETAILS</th>
            </tr>
          </thead>
          <tbody>
            {/* {bookings.map((booking, i) => ( */}
              <tr key={booking.user._id}>
                {/* <td>{booking._id}</td> */}
                <td>{booking.bookingItems[0].name}</td>
                <td>{formatter.format(new Date(booking.createdAt))}</td>
                <td>
                  {booking.flightDetails.arrivalAirline && (
                    <>
                      <u>Arrival Airline:</u><br/>{booking.flightDetails.arrivalAirline}<br/>
                    </>
                  )}
                  {booking.flightDetails.arrivalFlightNo && (
                    <>
                      <u>Arrival Flight #:</u><br/>{booking.flightDetails.arrivalFlightNo}<br/>
                    </>
                  )}
                  {booking.flightDetails.departureAirline && (
                    <>
                      <u>Departure Airline:</u><br/>{booking.flightDetails.departureAirline}<br/>
                    </>
                  )}
                  {booking.flightDetails.departureFlightNo && (
                    <>
                      <u>Departure Flight #:</u><br/>{booking.flightDetails.departureFlightNo}<br/>
                    </>
                  )}
                  {booking.flightDetails.departureDate && (
                    <>
                      <u>Departure Date:</u><br/>{booking.flightDetails.departureDate}<br/>
                    </>
                  )}
                  {/* {booking.flightDetails.departureTime && (
                    <>
                      <u>Departure Time:</u><br/>{booking.flightDetails.departureTime}<br/>
                    </>
                  )} */}
                  {booking.flightDetails.arrivalDate && (
                    <>
                      <u>Arrival Date:</u><br/>{booking.flightDetails.arrivalDate}<br/>
                    </>
                  )}
                  {/* {booking.flightDetails.arrivalTime && (
                    <>
                      <u>Arrival Time:</u><br/>{booking.flightDetails.arrivalTime}<br/>
                    </>
                  )} */}
                </td>
                <td>
                {booking.flightDetails.departureTime && (
                    <>
                      <u>Departure Time:</u><br/>{booking.flightDetails.departureTime}<br/>
                    </>
                  )}

                  {booking.flightDetails.arrivalTime && (
                    <>
                      <u>Arrival Time:</u><br/>{booking.flightDetails.arrivalTime}<br/>
                    </>
                  )}
                </td>
                <td>
                  {/* Map through the array of passengers in the booking and list all names */}
                  {booking.passengers.map((passenger, i) => (
                    <>
                      {passenger.name}<hr/><br/>
                    </>
                  ))}
                </td>
                <td>
                  {/* Map through the array of passengers in the booking and list all nationalities */}
                  {booking.passengers.map((passenger, i) => (
                    <>
                      {passenger.nationality}<hr/><br/>
                    </>
                  ))}
                </td>
                <td>
                  {/* Map through the array of passengers in the booking and list all passport numbers */}
                  {booking.passengers.map((passenger, i) => (
                    <>
                      {passenger.passportNumber}<hr/><br/>
                    </>
                  ))}
                </td>
                <td>
                  {/* Map through the array of passengers in the booking and list all DOBs */}
                  {booking.passengers.map((passenger, i) => (
                    <>
                      {passenger.dateOfBirth}<hr/><br/>
                    </>
                  ))}
                </td>
                
                <td>${booking.totalPrice.toFixed(2)}</td>
                <td>
                  {booking.isDelivered ? (
                    <i className='fas fa-check' style={{ color: 'green' }}></i>
                  ) : (
                    <i className='fas fa-times' style={{ color: 'red' }}></i>
                  )}
                </td>
                <td>
                  <LinkContainer to={`/booking/${booking._id}`}>
                    <Button variant='light' className='btn-sm'>
                      VIEW
                    </Button>
                  </LinkContainer>
                </td>
              </tr>
            {/* ))} */}
          </tbody>
        </Table>
        ))}
        
        </>
      )}
    </>
  )
}

export default BookingListScreen

// To reverse the from oldest to newest, use this:
// bookings.sort((a, b) => {
//   return new Date(a.createdAt) - new Date(b.createdAt);
// });