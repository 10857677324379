const images = [
    {
      id: 1,
      src:
        "../images/1.jpg",
      alt: "The world"
    },
    {
      id: 2,
      src:
        "../images/2.jpg",
      alt: "Train"
    },
    {
      id: 3,
      src:
        "../images/3.jpg",
      alt: "Laptop"
    },
    {
      id: 4,
      src:
        "../images/4.jpg",
      alt: "Laptop"
    },
    {
      id: 5,
      src:
        "../images/5.jpg",
      alt: "Laptop"
    },
    {
      id: 6,
      src:
        "../images/6.jpg",
      alt: "Laptop"
    },
    {
      id: 7,
      src:
        "../images/7.jpg",
      alt: "Laptop"
    },
    {
      id: 8,
      src:
        "../images/8.jpg",
      alt: "Laptop"
    },
  ];
  export default images;