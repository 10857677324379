import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'
// import { verifyUser } from '../actions/userActions'

const VerifyPendingScreen = ({ match, history }) => {

  // const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
    
    return (
      <>
        <Container>
          <Row className='justify-content-md-center'>
            <Col xs={12} sm={12} md={12} lg={12} className="mt-50">
              <h2>Confirm your account</h2>
              <p>Email: {userInfo.email}</p>
              <p>An email has been sent out with a verification link, check your spam folder as well!
              </p>
              <p>We can't wait for you to join!</p>
            </Col>
          
          </Row>
        </Container>
      </>
    )

  // }
}

export default VerifyPendingScreen;
