import React from 'react'
import { Nav } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

const CheckoutSteps = ({ step1, step2, step3 }) => {
  return (
    <Nav className='justify-content-center mb-4'>
      <Nav.Item>
       <LinkContainer to='/cart'>
            <Nav.Link>Service</Nav.Link>
        </LinkContainer>
      </Nav.Item>

      <Nav.Item>
        {step1 ? (
          <LinkContainer to='/add-passengers'>
            <Nav.Link>Passengers</Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link disabled>Passengers</Nav.Link>
        )}
      </Nav.Item>

      <Nav.Item>
        {step2 ? (
          <LinkContainer to='/flight-details'>
            <Nav.Link>Flight Details</Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link disabled>Flight Details</Nav.Link>
        )}
      </Nav.Item>

      <Nav.Item>
        {step3 ? (
          <LinkContainer to='/placebooking'>
            <Nav.Link>Place Booking</Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link disabled>Place Booking</Nav.Link>
        )}
      </Nav.Item>
    </Nav>
  )
}

export default CheckoutSteps
