import React, {useState, useEffect} from 'react'
import { Row, Col } from 'react-bootstrap'
import Meta from '../components/Meta'

const PrivacyPolicyScreen = ({ match }) => {

  const [posts, setPosts] = useState(null);

    useEffect(() => {
      async function loadPosts() {
        const response = await fetch('https://leconcierge.dreamhosters.com/wp-json/wp/v2/pages/129');
        if(!response.ok) {
          // oups! something went wrong
          return;
        }
        
        const posts = await response.json();
        // console.log("Rates:", posts)
        setPosts(posts);
        // console.log(posts)
      }
    
      loadPosts();
   }, [])

   if (posts === null) return null

  return (
    <>
      <Meta />
     
      <h1>Privacy Policy</h1>
      
        <>
          <Row>
            <Col sm={12} md={12} lg={12} xl={12}>
              <div dangerouslySetInnerHTML={{__html: posts.content.rendered}} />
            </Col>
          </Row>
          
          
        </>
    </>
  )
}

export default PrivacyPolicyScreen
